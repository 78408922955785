import { SocialType } from "components/SocialsShare/SocialsShare";
import React, { FC } from "react";
import TwitterIcon from "./Twitter"
import { useAppSelector } from "app/hooks";
import { selectDarkmodeState } from "app/darkmode/darkmode";
export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsDemo: SocialType[] = [
  {
    id: "Facebook",
    name: "Facebook",
    icon: "lab la-facebook-square",
    href: "https://www.facebook.com/dzinsights",
  },
  { id: "Twitter", name: "Twitter", icon: "lab la-twitter", image: TwitterIcon, href: "https://twitter.com/dzinsights" },
  { id: "Youtube", name: "Youtube", icon: "lab la-youtube", href: "https://www.youtube.com/@dzinsights" },
  { id: "Instagram", name: "Instagram", icon: "lab la-instagram", href: "https://www.instagram.com/dzinsights/" },
  {id: "Linkedin", name: "Linkedin", icon: "lab la-linkedin-in", href: "https://www.linkedin.com/company/dz-insights" }
];

export const SOCIALS_2 = socialsDemo;

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block",
  socials = socialsDemo,
}) => {
  const darkmodeState = useAppSelector(selectDarkmodeState);
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {socials && socials.map(({image: IconSvg, ...item}, i) => (
        <a
          key={i}
          className={`${itemClass}`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          {IconSvg && <IconSvg fill={darkmodeState ? "#fff" : "#4c5563"}/>}
          {!IconSvg && <i className={item.icon}></i>}
        </a>
      ))}
    </nav>
  );
};

export default SocialsList;
