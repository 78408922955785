import React, { FC, ReactNode, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PostDataType, TaxonomyType } from "data/types";
import SingleContent from "./SingleContent";
import { CommentType } from "components/CommentCard/CommentCard";
import SingleRelatedPosts from "./SingleRelatedPosts";
import { useAppDispatch } from "app/hooks";
import { Sidebar } from "./Sidebar";
import SingleHeader from "./SingleHeader";
import { getAuthor, getBlog, searchBlogs } from "app/blogs/blogs";
import SubHeader from "components/Header/SubHeader";
import { BlogResponseType } from "types/Blog";
import { PostDataConverter } from "app/modals/PostDataConverter";
import { selectAuthState } from "app/auth/authSlice";

export interface PageSingleTemp3SidebarProps {
  className?: string;
}

export interface SinglePageType extends PostDataType {
  tags: TaxonomyType[];
  content: string | ReactNode;
  comments: CommentType[];
}

const PageSingleTemp3Sidebar: FC<PageSingleTemp3SidebarProps> = ({
  className = "",
}) => {
  const dispatch = useAppDispatch();
  const [pageData, setPageData] = useState<SinglePageType>();
  const [relatedPosts, setRelatedPosts] = useState<PostDataType[]>([]);
  const [relatedAuthorPosts, setRelatedAuthorPosts] = useState<PostDataType[]>([]);
  const { slug } = useParams<{ slug: string }>()
  const [error, setError] = useState<string>('')
  const history = useHistory()
  // UPDATE CURRENTPAGE DATA IN PAGEREDUCERS
  useEffect(() => {      
    if (!slug.includes('discover')){
      dispatch(getBlog(slug))
      .unwrap()
      .then((res: SinglePageType) => {
        !res ? history.push('/errorpage') : setPageData(res)
       getRelatedPosts(res.categories[0].id)
       getAuthor(res.author.id)
       .then((res)=>{
        getRelatedAuthorPosts(res.email || "")
       })
      })
      .catch(err => {
        if(err.status === '404') {
          history.push('/page404')
        }else {
          history.push('/errorpage')
        }
      })
    };
  },[slug]);

const getRelatedPosts = (id:string | number) => {
  const params = {
    filterAnd: id ?  `status|eq|APPROVED&accessPlatform|eq|INSIGHTS&category|eq|${id}` : ""
  }
  const urlPath= new URLSearchParams(params).toString();
  searchBlogs(urlPath)
    .then((res)=>{
      const _post = res.content.map((val:BlogResponseType)=>{
        return new PostDataConverter(val)
      })       
      setRelatedPosts(_post.filter((_, i) => i >= 0 && i < 5));        
    })
    .catch((err) => setError(err?.message || "Something wrong, Please try again."));
}
const getRelatedAuthorPosts = (id:string) => {
  const params = {
    filterAnd: id ?`status|eq|APPROVED&accessPlatform|eq|INSIGHTS&createdBy|eq|${id}`: ""
  }
  const urlPath= new URLSearchParams(params).toString();
  searchBlogs(urlPath)
    .then((res)=>{
      const _post = res.content.map((val:BlogResponseType)=>{
        return new PostDataConverter(val)
      })       
      setRelatedAuthorPosts(_post.filter((_, i) => i >= 0 && i < 5));        
    })
    .catch((err) => setError(err?.message || "Something wrong, Please try again."));
}
  return (
    <>   
    {pageData && <SubHeader data={pageData} />}
      <div
        className={`nc-PageSingleTemp3Sidebar ${className}`}
        data-nc-id="PageSingleTemp3Sidebar"
      >
        <header className="relative pt-16 z-10 md:py-20 lg:py-28 bg-neutral-900 dark:bg-black">
          {/* SINGLE HEADER */}
          <div className="dark container relative z-10">
            <div className="max-w-screen-md">
              {pageData && <SingleHeader
                hiddenDesc
                metaActionStyle="style2"
                pageData={pageData }
              />}
            </div>
          </div>
          {/* FEATURED IMAGE */}
          <div className="mt-8 md:mt-0 md:absolute md:top-0 md:right-0 md:bottom-0 md:w-1/2 lg:w-2/5 2xl:w-1/3">
            <div className="hidden md:block absolute top-0 left-0 bottom-0 w-1/5 from-neutral-900 dark:from-black bg-gradient-to-r"></div>
            <img
              className="block w-full h-full object-cover"
              src={pageData?.featuredImage}
              alt={pageData?.altTag}
            />
          </div>
        </header>

        {/* SINGLE MAIN CONTENT */}
        <div className="container flex flex-col my-10 lg:flex-row ">
          <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
            { pageData && <SingleContent data={pageData} /> }
          </div>
          <div className="w-full mt-12 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3">
            <Sidebar />
          </div>
        </div>

        {/* RELATED POSTS */}
       { relatedPosts && relatedAuthorPosts && <SingleRelatedPosts relatedPosts = {relatedPosts} moreFromAuthorPosts={relatedAuthorPosts}/>}
      </div>
    </>
  );
};

export default PageSingleTemp3Sidebar;
