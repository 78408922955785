import Heading from "components/Heading/Heading";
import NcImage from "components/NcImage/NcImage";
import React from "react";

export interface WhatWeOffer {
  id: string;
  heading: string;
  subHeading: string;
}

const WhatWeOffer: WhatWeOffer[] = [
  {
    id: "1",
    heading: "Editorial Support 💬",
    subHeading:
      "Get support from the editorial team to refine your writing style, improve grammar, and ensure polished content.",
  },
  {
    id: "2",
    heading: "Global Reach 🌐",
    subHeading: "Break Down Geographical Barriers And Connect With A Diverse And Engaged Audience.",
  },
  {
    id: "3",
    heading: "Marketing and Promotion 📲",
    subHeading:
      "Earn recognition in the digital publishing world with our marketing and promotional support to authors.",
  },
];


const SectionWhatWeOffer = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
        desc="Whether you're an established writer or an aspiring newcomer, we help you succeed in the digital publishing landscape through:"
      >
        What we offer
      </Heading>
      <div className="grid md:grid-cols-2 gap-5 lg:grid-cols-3 xl:gap-8">
        {WhatWeOffer.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-white dark:bg-black/20 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-xl font-semibold leading-none text-neutral-900 md:text-xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionWhatWeOffer;
