import React, { useEffect, useState } from "react";
import SectionLatestPosts from "./SectionLatestPosts";
import SectionSliderPosts from "./SectionSliderPosts";
import SectionMagazine1 from "./SectionMagazine1";
import SectionLargeSlider from "./SectionLargeSlider";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import { PostDataType } from "data/types";
import { searchBlogs } from "app/blogs/blogs";
import {
  DEMO_POSTS,
} from "data/posts";
import { DEMO_CATEGORIES } from "data/taxonomies";
import { DEMO_AUTHORS } from "data/authors";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import SectionMagazine4 from "./SectionMagazine4";
import SectionAds from "./SectionAds";
import SectionGridPosts from "./SectionGridPosts";
import SectionMagazine7 from "./SectionMagazine7";
import SectionMagazine8 from "./SectionMagazine8";
import SectionMagazine9 from "./SectionMagazine9";
import LatestPostWrapper from "./LatestPostWrapper";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Loader from "components/Loader";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getEditorBlogs,getTop10Categories, selectBlogState ,getTop10Authors,getNewestAuthor} from "app/blogs/blogs";
import { PostAuthorType } from "data/types";
import { Pageable ,BlogResponseType } from "types/Blog";
import { PostDataConverter } from "app/modals/PostDataConverter";
import {AccessPlatform} from "../../enum";
import { Alert } from "components";
import { AuthorConverter } from "app/modals/AuthorConverter";
const POSTS: PostDataType[] = DEMO_POSTS;
const MAGAZINE1_TABS = ["all", "Garden", "Fitness", "Design"];
const MAGAZINE1_POSTS = DEMO_POSTS.filter((_, i) => i >= 8 && i < 16);
const MAGAZINE2_POSTS = DEMO_POSTS.filter((_, i) => i >= 0 && i < 7);


const PageHome: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [authors, setAuthors] = useState<PostAuthorType[]>([]);
  const [newestAuthors , setNewestAuthors] = useState<PostAuthorType[]>([]);
  const [tabs, setTabs] = useState<string[]>(["all"]);
  const [latestArticles, setLatestArticles] = useState<PostDataType[]>([]);
  const [popularPosts, setPopularPosts] = useState<PostDataType[]>([]);
  const [allArticles, setAllArticles] = useState<PostDataType[]>([]);
  const [error, setError] = useState<string>('')
  const dispatch = useAppDispatch();
  const [data, setData] = useState<Array<PostDataType>>([])
  const [page, setPage] = useState<Pageable>({
    pageNo: 0,
    pageSize: 20,
  });
  const { trendingTopic } = useAppSelector(selectBlogState);

  const fetchPopularPost = () => {
    const params = {
      pageNo: `${page.pageNo}`,
      pageSize: `${page.pageSize}`,
      orders: `totalViewer|desc||publishedDate|desc`,
      filterAnd : `status|eq|APPROVED&accessPlatform|in|${AccessPlatform.INSIGHTS}`
    }
    const urlPath= new URLSearchParams(params).toString();
    searchBlogs(urlPath)
      .then((res)=>{
        const _post = res.content.map((val:BlogResponseType)=>{
          return new PostDataConverter(val)
        })
        setPopularPosts(_post.filter((_, i) => i >= 0 && i < 7));
        })
      .catch((err) => setError(err?.message || "Something wrong, Please try again."));
  }

  useEffect(() => {
    fetchPopularPost();
  }, [])

  useEffect(() => {
    setLoading(true)
    dispatch(getTop10Categories())
      .unwrap()
      .then((res) => {
        setLoading(false)
        const recentCategories = res.recent.slice(0,3)
        setTabs((prev) => {
          return [
            ...prev,
            ...recentCategories.map((cat) => {
              return cat.name;
            }),
          ];
        });
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.message || "Something wrong, Please try again.")
      });
    const params = {
      pageNo: `${page.pageNo}`,
      pageSize: `${page.pageSize}`,
      orders: `publishedDate|desc||totalViewer|desc`,
      filterAnd : `status|eq|APPROVED&accessPlatform|in|${AccessPlatform.INSIGHTS}`
    }
    const urlPath= new URLSearchParams(params).toString();
    searchBlogs(urlPath)
      .then((res)=>{
        const _post = res.content.map((val:BlogResponseType)=>{
          return new PostDataConverter(val)
        })
          setLatestArticles(_post.filter((_, i) => i >= 0 && i < 7));
          setAllArticles(_post)
        })
      .catch((err) => setError(err?.message || "Something wrong, Please try again."));
      const param = {
        pageNo: 0,
        pageSize: 10,
      }
    getTop10Authors(param)
      .then((res) => {
        const _post = res.content.map((val)=>{
          return new AuthorConverter(val)
        })
        setAuthors(_post);
      })
      .catch((err) => setError(err?.message || "Something wrong, Please try again."));      
    getEditorBlogs(page)
      .then((res:PostDataType[]) =>{
       setData(res);
      })
      .catch((err) => setError(err?.message || "Something wrong, Please try again."));

      getNewestAuthor()
      .then((res) =>{
        setNewestAuthors(res)
      })
      .catch((err) =>{
        setError(err?.message || "Something wrong, Please try again.")
      })
  }, []);
  // Handle Category Update
  function changeCategory(id:string | undefined) {
    const params = {
      pageNo: `${page.pageNo}`,
      pageSize: `${page.pageSize}`,
      orders: id ? `publishedDate|desc` : `publishedDate|desc||totalViewer|desc`,
      filterAnd: id ? `status|eq|APPROVED&accessPlatform|eq|INSIGHTS&status|eq|APPROVED&category|eq|${id}` : `status|eq|APPROVED&accessPlatform|in|${AccessPlatform.INSIGHTS}`
    }
    const urlPath= new URLSearchParams(params).toString();
    searchBlogs(urlPath)
      .then((res)=>{
        const _post = res.content.map((val:BlogResponseType)=>{
          return new PostDataConverter(val)
        })
          setLatestArticles(_post.filter((_, i) => i >= 0 && i < 7));        
      })
      .catch((err) => setError(err?.message || "Something wrong, Please try again."));
  }
  return (
    <>
    {loading && (
      <div className="w-full top-0 bottom-0 right-0 left-0 fixed flex bg-[#111827e6] items-center z-10 justify-center	">
        <Loader />
      </div>
    )}
    <div className="nc-PageHome relative">
      <Helmet>
        <title>DZ Insights- Discover The World</title>
        <link rel = "canonical" href = {window.location.href} />
      </Helmet>
      {error && <Alert type="error" children={error} onClose={() => setError("")} />}
      {/* ======== ALL SECTIONS ======== */}
      <div className="relative overflow-hidden">
        {/* ======== BG GLASS ======== */}
        <BgGlassmorphism />

        {/* ======= START CONTAINER ============= */}
        <div className="container relative">
          {/* === SECTION  === */}
          {data.length>0 && <SectionLargeSlider
            className="pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-20"
            posts={data}
          />}

          {/* === SECTION  === */}
          <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderNewAuthors
              heading="Newest authors"
              subHeading="Say hello to future creator potentials"
              authors={newestAuthors.filter((_, i) => i < 10)}
              uniqueSliderClass="PageHome"
            />
          </div>

          {/* === SECTION 5 === */}
            <SectionSliderNewCategories
            className="py-16 lg:py-28"
            heading="Top trending topics"
            subHeading={`Discover ${trendingTopic.length} topics`}
            categories={trendingTopic.filter((_, i) => i < 10)}
            categoryCardType="card4"
            uniqueSliderClass="pageHome-section5"
          />

          {/* === SECTION 6 === */}
          {/* <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderPosts
              postCardName="card9"
              heading="Explore latest audio articles"
              subHeading="Click on the icon to enjoy the music or podcast 🎧"
              sliderStype="style2"
              posts={DEMO_POSTS_AUDIO.filter((_, i) => i > 3 && i < 10)}
              uniqueSliderClass="pageHome-section6"
            />
          </div> */}

          {/* === SECTION 4 === */}
          <SectionMagazine1
            className="py-16 lg:py-28"
            posts={latestArticles}
            tabs={tabs}
            onUpdate={changeCategory}
          />

          {/* === SECTION 3 === */}
       

          {/* === SECTION 7 === */}
          {/* <SectionMagazine7
            className="py-16 lg:py-28"
            posts={DEMO_POSTS_GALLERY.filter((_, i) => i < 6)}
          /> */}
        </div>

        {/* === SECTION 11 === */}
        {/* <div className="dark bg-neutral-900 dark:bg-black dark:bg-opacity-20 text-neutral-100">
          <div className="relative container">
            <SectionGridPosts
              className="py-16 lg:py-28"
              headingIsCenter
              postCardName="card10V2"
              heading="Explore latest video articles"
              subHeading="Hover on the post card and preview video 🥡"
              posts={DEMO_POSTS_VIDEO.filter((_, i) => i > 5 && i < 12)}
              gridClass="md:grid-cols-2 lg:grid-cols-3"
            />
          </div>
        </div> */}

        <div className="container ">
          {/* === SECTION 9 === */}
          {/* <SectionMagazine8
            className="py-16 lg:py-28"
            posts={DEMO_POSTS_AUDIO.filter((_, i) => i < 6)}
          /> */}

          {/* === SECTION 9 === */}
          {/* <div className="relative py-16">
            <BackgroundSection />
            <SectionMagazine9
              posts={DEMO_POSTS_AUDIO.filter((_, i) => i >= 6 && i < 16)}
            />
          </div> */}

          {/* === SECTION 5 === */}
          <SectionGridAuthorBox
            className="py-16 lg:py-28"
            heading=" Top 10 author of the month"
            subHeading="Rating based on customer reviews"
            authors={authors.filter((_, i) => i < 10)}
          />

          {/* === SECTION 8 === */}
          <div className="relative py-16">
            <BackgroundSection />
            <SectionBecomeAnAuthor />
          </div>

          {/* === SECTION 11 === */}
          {/* <SectionMagazine4
            className="py-16 lg:py-28"
            heading="Life styles 🎨 "
            posts={MAGAZINE2_POSTS}
            tabs={MAGAZINE1_TABS}
          /> */}

          {/* === SECTION 14 === */}
          <SectionSliderPosts
              postCardName="card11"
              heading="More Articles"
              subHeading={`Over ${allArticles.length} articles`}
              posts={allArticles.filter(
                (p, i) => i >= 0 && i < 20 && p.postType === "standard"
              )}
              sliderStype="style2"
              uniqueSliderClass="pageHome-section12"
              className="py-16 lg:py-28"
            />
          {/* === SECTION 15 === */}
          {/* <SectionVideos className="py-16 lg:py-28" /> */}

          {/* === SECTION 17 === */}
          <LatestPostWrapper      
            widgetPosts={popularPosts.filter((_, i) => i >= 0 && i < 7)}
            categories={DEMO_CATEGORIES.filter((_, i) => i >= 0 && i < 8)}
            tags={DEMO_CATEGORIES}
            authors={authors.filter((_, i) => i >= 0 && i < 10)}
          />
          
          {/* === SECTION 12 === */}
          <div className="relative">
          <SectionSubscribe2 className="pb-20" />
           
          </div>
        </div>
        {/* ======= END CONTAINER ============= */}
      </div>
    </div>
    </>
  );
};

export default PageHome;
