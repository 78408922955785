import React from "react";
import { SOCIAL_LOGIN_LIST } from "enum";
import { useAppSelector } from "app/hooks";
import { NcImage } from "components";

const SocialLogin: React.FC<{}> = () => {
  const { isDarkMode } = useAppSelector(state => state.darkmode);

  return (
    <div className="max-w-md mx-auto space-y-6">
      <div className="grid gap-3">
      {SOCIAL_LOGIN_LIST.map(({image: Icons, ...item}) => (
        <a
          key={item.name}
          href={item?.link || ""}
          className="flex w-full rounded-lg bg-primary-50 hover:bg-primary-100 dark:bg-neutral-800 dark:hover:bg-neutral-700 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px] nc-NcLink"
        >
          {Icons && <Icons fill={isDarkMode ? "#fff" : "#4c5563"} />}
          {!Icons && (
            <NcImage width={18} height={18} className="flex-shrink-0" src={item.icon} alt={item.name}/>
          )}
          <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
            {item.name}
          </h3>
        </a>
      ))}
      </div>
    </div>
  );
};

export default SocialLogin;
