import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import jwtDecode from 'jwt-decode';
import { useFormik } from "formik";
import { loginSchema as validationSchema } from "utils/validation";
import { LoginPayload, SocialPayload } from "types/Auth";
import FirstLogin from "./FirstLogin";
import {
  ButtonPrimary,
  LayoutPage,
  NcLink,
  Input,
  Alert,
  NcModal,
} from "components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { emailUnsubscribe, emailVerify, fetchGoogleData, loginAction, logOut, selectAuthState, socialLogin} from "app/auth/authSlice";
import SocialLogin from "./SocialLogin";

const PageLogin: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { token } = useAppSelector(selectAuthState);
  const query = new URLSearchParams(history?.location?.search);
  const pathnameQuery = history?.location.pathname;
  const { isVerifyRequired = false, email = "" }: any = history?.location?.state || {};
  const [isUnsubscribe, setIsUnsubscribe] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const initialValues: LoginPayload = {
    email: "",
    password: "",
  };

  useEffect(() => {
    if(token) {
      history.push("/")
    }
  }, [])

  const onSubmit = (values: LoginPayload) => {
    setLoader(true);
    dispatch(loginAction(values))
      .unwrap()
      .then(() => history.push('/'))
      .catch((err) =>{
        if(err.status == "503" || "504"){
          setError("Having trouble connecting with server, Please try again after sometime")
        } 
        setError(err?.message || "Something wrong, Please try again.")
      })
      .finally(() => setLoader(false))
  };

  const formicForm = useFormik({ enableReinitialize: true, initialValues, validationSchema, onSubmit });

  const renderMailContent = () => {
    return (
      <div className=" mt-0 mb-2 mx-2 space-y-6">
        <div className="text-center">
          <h5 className="dark:bg-neutral-800 text-neutral-800 mb-2 text-sm">
            We sent a verification link to
          </h5>
          <span className="mt-2">
             <h5 className="font-semibold text-neutral-900 dark:text-neutral-200 text-lg">
              {email}
             </h5>
          </span>
          <span>
            <h5 className="m-2 text-sm text-neutral-800">Please check your inbox and follow the instructions.</h5>
          </span>
          <span>
            <h5 className="mt-6 font-semibold text-neutral-900 dark:text-neutral-200 text-lg">Didn’t find our email?</h5>
            <h5 className="m-2 text-sm text-neutral-800">The mail can also be in your spam folder, remember to cross-check it.</h5>
          </span>
          <span>
            <h5 className="m-2 text-neutral-800 dark:text-neutral-200 dark:text-sm text-sm">P.S. Still finding trouble in the verification of your account?</h5>
          </span>
          <span className="text-neutral-800 dark:text-neutral-200 text-sm italic font-semibold">
            Contact Our <a href="https://www.dzinsights.com/contact" target="blank" className="text-blue-800 underline">Support Team </a>and we will help you set up the account manually.
          </span>
        </div>
      </div>
    );
  };

  const renderFirstLoginForm = () => {
    return (
        <div>
          <FirstLogin onClosedReceive={onHandleClose}/>
        </div>
    )
  }
  const emailVerifyApply = () => {
    const id = query?.get('id');
    if (id) {
      setLoader(true);
        emailVerify(id)
        .then(() => setIsVerified(true))
        .catch((err) => setError(err?.message || "Something wrong, Please try again."))
        .finally(() => setLoader(false))
    }
  };

 // handle unsubscribe user email 
 const handleEmailUnsubscribe = () => {
  if (pathnameQuery.includes('unsubscribe')) {
    const emailId =pathnameQuery.split("/").pop();
    emailId && emailUnsubscribe(emailId)
      .then(() => setIsUnsubscribe(true))
      .catch((err) => setError(err?.message || "Something wrong, Please try again."))
      .finally(() => setLoader(false))   
  }
};
  const socialLoginApply = (payload: SocialPayload) => {
    setLoader(true);
    dispatch(socialLogin(payload))
      .unwrap()
      .then((res) => {
        if(res.firstLogin || !res.userResponse.email) {
          setIsOpen(true)
        }else
        {
          history.push('/')
        }
      }).catch((err) => {
        setError(err?.message || "Something wrong, Please try again.");
        history.push("/login")
      })
      .finally(() => setLoader(false))
  };

  useEffect(() => {
    emailVerifyApply();
    handleEmailUnsubscribe();
    const code = query?.get('code') || "";
    const provider = query?.get('state') || "";
    const twitterOAuthToken = query?.get('oauth_token') || "";
    const twitterOAuthVerifier = query?.get('oauth_verifier') || "";

    const payload: SocialPayload = {
      facebookId: "",
      facebookToken: "",
      googleId: "",
      googleToken: "",
      linkedinId: "",
      linkedinOAuthCode: "",
      twitterId: "",
      twitterOAuthToken: "",
      twitterOAuthVerifier: "",
    };

    if (twitterOAuthToken && twitterOAuthVerifier) {
      socialLoginApply({ ...payload, twitterOAuthToken, twitterOAuthVerifier });
    }

    if (provider === "LINKEDIN" && code) {
      socialLoginApply({ ...payload, linkedinOAuthCode: code });
    }

    if (provider === "GOOGLE" && code) {
      setLoader(true);
      dispatch(fetchGoogleData(code))
        .unwrap()
        .then((res) => {
          const gUser: any = jwtDecode(res?.id_token || "");
          socialLoginApply({ ...payload, googleId: gUser?.sub, googleToken: res?.id_token || "" });
        })
        .catch((err)=>{
          setError(err?.message || "Something wrong, Please try again.");
           history.push("/login")
        })
    }
  }, []);

  const onHandleClose = () => {
    dispatch(logOut()).unwrap().finally(() => {
      history.push('/')
    })
  }

  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
      <Helmet>
        <title>Log in- DZ Insights</title>
      </Helmet>
      <LayoutPage
        subHeading="Welcome to DZ Insights!"
        heading="Log in"
      >
        {error && <Alert type="error" children={error} onClose={() => setError("")} />}
        <div className="max-w-md mx-auto space-y-6">
          <SocialLogin />
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <div className="grid grid-cols-1 gap-6">
            <Input
              disabled={loader}
              form={formicForm}
              placeholder="guest@example.com"
              name="email"
              text="Email"
              className="mt-1"
            />
            <Input
              disabled={loader}
              form={formicForm}
              placeholder="********"
              name="password"
              type="password"
              text="Password"
              className="mt-1"
            />
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                <NcLink aria-link="forget password page" to="/forgot-pass" className="text-sm">
                  Forgot password?
                </NcLink>
              </span>
            </label>
            <ButtonPrimary
              type="button"
              onClick={formicForm?.handleSubmit}
              loading={loader}
              disabled={loader}
            >
              Continue
            </ButtonPrimary>
          </div>
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <NcLink aria-link="create new account page" to="/signup">Create an account</NcLink>
          </span>
        </div>
        <NcModal
          isOpenProp={isVerifyRequired}
          modalTitle="Verify your email"
          contentExtraClass="max-w-screen-sm"
          renderTrigger={() => null}
          onCloseModal={() => history.push("/login")}
          renderContent={renderMailContent}
        />
        <NcModal
          isOpenProp={isVerified}
          modalTitle="Congratulations"
          contentExtraClass="max-w-screen-sm"
          renderTrigger={() => null}
          onCloseModal={() => {
            setIsVerified(false);
            history.push("/login");
          }}
          renderContent={() => ("Your Mail Has Been Verifed You Can Now Login")}
        />
        <NcModal
            isOpenProp={isOpen}
            modalTitle={"Update Password"}
            contentExtraClass="max-w-screen-sm"
            renderTrigger={() => null}
            onCloseModal={() => {
              onHandleClose()
             }}
            renderContent={renderFirstLoginForm}
        />
           <NcModal
          isOpenProp={isUnsubscribe}
          modalTitle="Newsletter Unsubscribed Successfully 📰"
          contentExtraClass="max-w-screen-sm"
          renderTrigger={() => null}
          onCloseModal={() => {
            setIsUnsubscribe(false);
            history.push("/login");
          }}
          renderContent={() => ("If you change your mind in the future and wish to resubscribe, you can easily do so by visiting our website")}
        />
      </LayoutPage>
    </div>
  );
};

export default PageLogin;
