import React, { FC, useState, useEffect } from "react";
import { PostAuthorType, PostDataType, TaxonomyType } from "data/types";
import Pagination from "components/Pagination/Pagination";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { DEMO_AUTHORS } from "data/authors";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import Avatar from "components/Avatar/Avatar";
import SocialsList from "components/SocialsList/SocialsList";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import Card11 from "components/Card11/Card11";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import { DEMO_CATEGORIES } from "data/taxonomies";
import ButtonSecondary from "components/Button/ButtonSecondary";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import NcImage from "components/NcImage/NcImage";
import { useParams } from "react-router-dom";
import {getAuthor,followUser,followUserStatus, getTop10Authors, getAllCategory } from "../../app/blogs/blogs";
import {searchBlogs} from "../../app/blogs/blogs";
import {BlogResponseType, CategoryResponse} from "../../types/Blog";
import {PostDataConverter} from "../../app/modals/PostDataConverter";
import {ListBoxItemType} from "../../components/NcListBox/NcListBox";
import { selectAuthState } from "app/auth/authSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { CategoryConverter } from "app/modals/CategoryConverter";
import SectionSliderPosts from "containers/PageHome/SectionSliderPosts";
import { AuthorConverter } from "app/modals/AuthorConverter";
import { AccessPlatform } from "enum";
import { Alert } from "components";

export interface PageAuthorProps {
  className?: string;
}

const AUTHOR: PostAuthorType = DEMO_AUTHORS[0];
const FILTERS = [
  { name: "Most Recent" },
  // { name: "Curated by Admin" },
  // { name: "Most Appreciated" },
  // { name: "Most Discussed" },
  { name: "Most Viewed" },
];
const TABS = ["Articles", "Favorites", "Saved"];

const PageAuthor: FC<PageAuthorProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch()
  const { token } = useAppSelector(selectAuthState);
  const [category, setCategory] = useState<TaxonomyType[]>([])
  const [error, setError] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [authors, setAuthors] = useState<PostAuthorType[]>([])
  const [followStatus, setFollowStatus] = useState<boolean>(false);
  const [showSocialLinks, setShowSocialLinks] = useState<boolean>(false);
  const [showFollowBtn, setFollowBtn] = useState<boolean>(false);
  const [tabActive, setTabActive] = useState<string>(TABS[0]);
  const { slug } = useParams<{ slug: string }>()
  const [posts, setPost] = useState<Array<PostDataType>>([])
  const [authorBlog, setAuthorBlog] = useState<Array<PostDataType>>([])
  const [pagination, setPagination] = useState({
    pageNo: 0, pageSize:10
  })
  const [authorDetail, setAuthorDetail] = useState<PostAuthorType>();
  const [selectedOrderValue, setSelectedOrderValue] = useState<ListBoxItemType>()
  useEffect(()=>{
    getAuthor(slug).then((res:PostAuthorType)=>{
     res.socialLink && res.socialLink.map((i)=> {
      i.href !== 'null' &&setShowSocialLinks(true)
     })
      setAuthorDetail(res)
      getauthorBlog(res?.email||"")
    })
    .catch((error)=> (
      setError(error?.message || "Something wrong, Please try again.")
    ))
   {token && followUserStatus(slug)
    .then((res)=>{
      setFollowStatus(res.following) 
      setFollowBtn(true) 
    })
    .catch((error) => {
      setFollowBtn(false) 
      setError(error?.message || "Something wrong, Please try again.")
    })}
  },[slug])

  useEffect(()=>{
    const params = {
      pageNo: '0',
      pageSize: '10',
      orders: 'publishedDate|desc',
      filterAnd : `status|eq|APPROVED&accessPlatform|in|${AccessPlatform.INSIGHTS}`
    }
    const urlPath= new URLSearchParams(params).toString();
    searchBlogs(urlPath)
        .then((res)=>{
          const _post = res.content.map((val:BlogResponseType)=>{
            return new PostDataConverter(val)
          })
          setPost(_post)
        })
        .catch((e)=> setError(e.message  || "Something wrong, Please try again."))
  },[selectedOrderValue])

  const handleClickTab = (item: string) => {
    if (item === tabActive) {
      return;
    }
    setTabActive(item);
  };

  const handleDataReceived = (data:ListBoxItemType) => {
    setSelectedOrderValue(data)
  };

    const handleFollowUser = () => {
      setLoader(true);  
      followUser({ follow: !followStatus, id:slug })
      .then(()=>{
        setLoader(false);
        setFollowStatus(!followStatus);
      })
      .catch((err) => {
        setLoader(false);
        setError(err?.message || "Something wrong, Please try again.")
      })
    }
    useEffect(() => {
      dispatch(getAllCategory())
      .unwrap()   
      .then((res) => {
        if(res) {        
          const data = res?.content?.map((t: CategoryResponse) => {
            return new CategoryConverter(t)
          })
          return setCategory(data)
        }
      })
      .catch((e)=> setError(e.message || "Something wrong, Please try again."))
      getTop10Authors(pagination)
      .then((res) => {
        const _post = res.content.map((val)=>{
          return new AuthorConverter(val)
        })
        setAuthors(_post);
      })
      .catch((err) => setError(err?.message || "Something wrong, Please try again."));
    },[])
    const getauthorBlog = (email:string) => {
      const params = {
        filterAnd:`status|eq|APPROVED&status|eq|APPROVED&accessPlatform|eq|INSIGHTS&createdBy|eq|${email}`,
        pageNo: '0',
        pageSize: '20',
        orders:"createAt|desc"
      }
      const urlPath= new URLSearchParams(params).toString();
      searchBlogs(urlPath)
      .then((res)=>{
        if(res) {     
          const data = res?.content?.map((t) => {
            return new PostDataConverter(t)
          })
          return setAuthorBlog(data)    
        }
      })
      .catch((e)=> setError(e.message  || "Something wrong, Please try again."))
    }
    
  return (
    <div className={`nc-PageAuthor  ${className}`} data-nc-id="PageAuthor">
      <Helmet>
        <title>DZ Insights- Discover The World</title>
      </Helmet>
      {error && <Alert type="error" children={error} onClose={() => setError("")} />}

      {/* HEADER */}
      <div className="px-2 xl:max-w-screen-2xl mx-auto">
        <div className="rounded-3xl md:rounded-[40px] relative aspect-w-16 aspect-h-12 sm:aspect-h-7 xl:sm:aspect-h-6 overflow-hidden ">
          <NcImage
            containerClassName="absolute inset-0"
            src="https://images.pexels.com/photos/459225/pexels-photo-459225.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="relative container -mt-20 lg:-mt-48">
          <div className=" bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-16 rounded-[40px] shadow-2xl flex flex-col sm:flex-row sm:items-center">
            <Avatar
              containerClassName="ring-4 ring-white dark:ring-0 shadow-2xl"
              imgUrl={authorDetail?.avatar}
              sizeClass="w-20 h-20 text-xl lg:text-2xl lg:w-36 lg:h-36"
              radius="rounded-full"
            />
            <div className="mt-5 sm:mt-0 sm:ml-8 space-y-4 max-w-lg">
              <h2 className="inline-block text-2xl sm:text-3xl md:text-4xl font-semibold">
                {authorDetail?.displayName}
              </h2>
              <span className="block text-sm text-neutral-6000 dark:text-neutral-300 md:text-base">
                {authorDetail?.desc}
              </span>
              
              <span className="flex">
                <>
             {showSocialLinks &&<SocialsList socials={authorDetail?.socialLink}/>}
              {showFollowBtn && <ButtonPrimary sizeClass="sm:px-5 float-left ml-5" onClick={handleFollowUser} loading={loader}>{followStatus? "Unfollow" : "Follow"}</ButtonPrimary>}
              </>
              </span >
             {error ? <p className="text-red-400 text-sm my-1">{error}</p> :null}
            </div>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
     {authorBlog.length > 0 &&
     <div className="relative py-16">
      <BackgroundSection />
      <SectionSliderPosts
        postCardName="card11"
        heading="My Contribution"
        subHeading={`Over ${authorBlog.length} articles`}
        posts={authorBlog}
        sliderStype="style2"
        uniqueSliderClass="pageHome-section12"
        />
      </div>}
        <main>
          {/* TABS FILTER */}
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <Nav className="sm:space-x-2">
              {TABS.map((item, index) => (
                <NavItem
                  key={index}
                  isActive={tabActive === item}
                  onClick={() => handleClickTab(item)}
                >
                  {item}
                </NavItem>
              ))}
            </Nav>
            <div className="block my-4 border-b w-full border-neutral-100 sm:hidden"></div>
            <div className="flex justify-end">
              <ArchiveFilterListBox lists={FILTERS} onDataReceived={handleDataReceived}  />
            </div>
          </div>

          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
            {posts?.map((post:PostDataType) => (
              <Card11 key={post.id} post={post} />
            ))}
          </div>

          {/* PAGINATION */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination />
          </div>
        </main>

        {/* === SECTION 5 === */}
        {category.length > 0 && <div className="relative py-16">
          <BackgroundSection />
          <SectionGridCategoryBox
            categories={category.filter((_, i) => i < 10)}
          />
        </div>}

        {/* === SECTION 5 === */}
        {authors.length > 0 ? <SectionSliderNewAuthors
          heading="Top Elite Authors"
          subHeading="Discover our elite writers"
          uniqueSliderClass="PageAuthor__slider"
          authors={authors.filter((_, i) => i < 10)}
        /> : null}

        {/* SUBCRIBES */}
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAuthor;
