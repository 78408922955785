import React from "react";
import Logo from "components/Logo/Logo";
import SocialsList from "components/SocialsList/SocialsList";
import { CustomLink } from "data/types";
import { Link } from "react-router-dom";
export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

// const widgetMenus: WidgetFooterMenu[] = [
//   {
//     id: "5",
//     title: "Getting started",
//     menus: [
//       { href: "#", label: "Installation" },
//       { href: "#", label: "Release Notes" },
//       { href: "#", label: "Upgrade Guide" },
//       { href: "#", label: "Browser Support" },
//       { href: "#", label: "Editor Support" },
//     ],
//   },
//   {
//     id: "1",
//     title: "Explore",
//     menus: [
//       { href: "#", label: "Design features" },
//       { href: "#", label: "Prototyping" },
//       { href: "#", label: "Design systems" },
//       { href: "#", label: "Pricing" },
//       { href: "#", label: "Customers" },
//     ],
//   },
//   {
//     id: "2",
//     title: "Resources",
//     menus: [
//       { href: "#", label: "Best practices" },
//       { href: "#", label: "Support" },
//       { href: "#", label: "Developers" },
//       { href: "#", label: "Learn design" },
//       { href: "#", label: "What's new" },
//     ],
//   },
//   {
//     id: "4",
//     title: "Community",
//     menus: [
//       { href: "#", label: "Discussion Forums" },
//       { href: "#", label: "Code of Conduct" },
//       { href: "#", label: "Community Resources" },
//       { href: "#", label: "Contributing" },
//       { href: "#", label: "Concurrent Mode" },
//     ],
//   },
// ];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
      <div className="container py-5 relative flex border-t justify-between items-center space-x-4 xl:space-x-8">
        <div className="items-center xl:flex space-x-12">
          
            <Logo height="80" width= "200"/>
            <div className="mt-2 flex items-center space-x-8 lg:items-start">
            <Link
            aria-label="contact us"
             to={"/contact"} 
            className="lg:flex lg:flex-wrap cursor-pointer flex items-center font-italic text-md dark:text-neutral-200 ">
              Contact Us
            </Link>
            <Link
            aria-label="guidelines"
            to={"/guidelines"} 
          className="lg:flex lg:flex-wrap cursor-pointer  hover:font-semibold  flex items-center font-italic text-md dark:text-neutral-200 ">
          Guidelines
          </Link>
            <Link
            aria-label="privacy policy"
            to={"/privacy-policy"} 
          className="lg:flex lg:flex-wrap cursor-pointer hover:font-semibold flex items-center font-italic text-md dark:text-neutral-200 ">
            Privacy Policy
            </Link>
            <Link
            aria-label="terms and conditions"
            to={"/terms-conditions"} 
          className="lg:flex lg:flex-wrap cursor-pointer  hover:font-semibold  flex items-center font-italic text-md dark:text-neutral-200 ">
          Terms & Conditions
          </Link>
            </div>
            
     <div className="hidden sm:block h-7 mt-4 border-l border-neutral-300 dark:border-neutral-6000"></div>
          <div className="flex items-center ">
            <SocialsList className="text-3xl mt-4 flex items-center space-x-8 lg:items-start" />
          </div>
        </div>
        {/* {widgetMenus.map(renderWidgetMenuItem)} */}
      </div>
  );
};

export default Footer;
