import React, { FC,useState } from "react";
import LayoutPage from "components/LayoutPage/LayoutPage";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import { Helmet } from "react-helmet";
import { useAppDispatch } from "app/hooks";
import {forgotNewPassword, forgotPassword} from "app/auth/authSlice";
import { useHistory } from "react-router-dom";
import {forgotPasswordPayload} from "../../types/Auth";
import {useFormik} from "formik";
import {forgotPasswordSchema as validationSchema} from "../../utils/validation";
import NcModal from "../../components/NcModal/NcModal";

export interface PageForgotPassProps {
  className?: string;
}

const PageForgotPass: FC<PageForgotPassProps> = ({ className = "" }) => {
  const history = useHistory()
  const [email,setEmail] = useState<string>('')
  const [emailMessage,setEmailMessage] = useState<boolean>(false)
  const [errMessage,setErrMessage] = useState<string>('')
  const [loader,setLoader] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [isOpenPopUs, setIsOpenPopUs] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const initialValues: forgotPasswordPayload = {
    otp: "",
    newPassword: "",
  };

  const onSubmit = (value: forgotPasswordPayload) => {
    if(value){
      setLoader(true)
      dispatch(forgotNewPassword(value)).unwrap().then((res)=>{
        if(res){
        setLoader(false)
        setIsOpenPopUs(true)
        setIsOpen(true)
        }
      })
      .catch((err)=>{
        setLoader(false)
        setEmailMessage(false)
        setErrMessage(err.message)
      })
    }
  };

  const handleSubmit = (e:any) => {
   e.preventDefault()
    if(email){
      setLoader(true)
      forgotPassword(email).then((res)=>{
        setEmailMessage(true)
        setIsOpen(false)
        setLoader(false)
        setErrMessage('')
      }).catch((res)=>{
        if(res.status === 425){
          setIsOpen(false)
        }
        setLoader(false)
        setEmailMessage(false)
        setErrMessage(res.message)
      })
    }
  }
  const formicForm = useFormik({ enableReinitialize: true, initialValues, validationSchema, onSubmit });
  return (
    <div
      className={`nc-PageForgotPass ${className}`}
      data-nc-id="PageForgotPass"
    >
      <Helmet>
        <title>Forgot Password- DZ Insights</title>
      </Helmet>
      <LayoutPage
        subHeading="We will sent reset password instruction to your email"
        headingEmoji="🔐"
        heading="Forgot password"
      >
        { emailMessage ? <p className="text-center text-green-500 mb-4">One time password has been sent, Please check your email.</p> : ''}
        {errMessage ? <p className="text-center text-red-500 mb-4">{errMessage}</p> : ''}
        {isOpen ?
          <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                name="email"
                value={email}
                onChange={ (e:any) =>{setEmail(e.target.value)} }
              />
            </label>
            <ButtonPrimary type="submit" loading={loader?loader: false} disabled={loader}>Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Go back for {` `}
            <NcLink aria-link="login page" to="/login">Sign in</NcLink>
            {` / `}
            <NcLink aria-link="registration page" to="/signup">Sign up</NcLink>
          </span>
        </div>
        :
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                OTP
              </span>
              <Input
              disabled={loader}
              form={formicForm}
              placeholder="Enter OTP"
              name="otp"
              type='text'
              className="mt-1"
              />
            </label>
             <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                New Password
              </span>
             <Input
              disabled={loader}
              form={formicForm}
              placeholder="********"
              name="newPassword"
              type="password"
              className="mt-1"
            />
            </label>
            <ButtonPrimary
              type="button"
              onClick={formicForm?.handleSubmit}
              loading={loader?loader: false}
              disabled={loader}
              className='forgotPassword-btn'
            >
              Continue
            </ButtonPrimary>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Go back for {` `}
            <NcLink aria-link="login page" to="/login">Sign in</NcLink>
            {` / `}
            <NcLink aria-link="registration page" to="/signup">Sign up</NcLink>
          </span>
        </div>
        }
        <NcModal
        isOpenProp={isOpenPopUs}
        modalTitle="Success"
        contentExtraClass="max-w-screen-sm"
        renderTrigger={() => null}
        onCloseModal={() => {
          setIsOpenPopUs(false);
          history.push('/login');
        }}
        renderContent={() => ("Updated Password Successfully")}
          />

      </LayoutPage>
    </div>
  );
};

export default PageForgotPass;
