import { useMemo,useState } from "react";
import { useHistory } from "react-router-dom";
import { MRT_ColumnDef } from "material-react-table";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { deleteBlog, getBlogsByCurrentUser, selectBlogState } from "app/blogs/blogs";
import DataTable, { DataTypes } from "../MaterialTable/MaterialReactTable";
import { Pageable } from "types/Blog";
import { BlogState } from "app/blogs/blogs";
import { Alert } from "components";
const DashboardPosts = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentUserBlogs :{content, totalElements} }: BlogState = useAppSelector(selectBlogState);
  const [refreshTable, setTableRefresh] = useState<boolean>(false)
  const [error, setError] =  useState<string>('')
  const edit = (id: string) =>{
    history.push(`/dashboard/posts/edit/${id}`)
  };
  const handleDelete = (id: string) => {
    deleteBlog(id)
      .then(() =>setTableRefresh(true))
      .catch((err) =>{
        setError(err?.message || "Something wrong, Please try again.");  
      }) 
  }
  const columns = useMemo<MRT_ColumnDef<DataTypes>[]>(
    () => [
      {
        accessorFn: (row) => `${row?.title ? row.title : "---"}`,
        id: 'title',
        accessorKey: 'title',
        header: 'Title',
      }, 
      {
        accessorKey: 'status',
        enableClickToCopy: false,
        header: 'Status',
      },
      {
        accessorKey: 'totalViewer',
        enableClickToCopy: false,
        header: 'Total Viewer',
        size: 300,
      },
    ], []);
    const fetchBlogs = (params: Pageable) => {
      dispatch(getBlogsByCurrentUser(params))
      .unwrap()
      .catch((error)=> (
        setError(error?.message || "Something wrong, Please try again.")
      ))
    };
  return (
    <>
    {error && <Alert type="error" children={error} onClose={() => setError("")} />}
    <div className="flex flex-col space-y-8">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle min-w-full px-1 sm:px-6 lg:px-8">
          <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
            <div>
              <DataTable
                column={columns}
                data={content}
                edit={edit} 
                handleDelete={handleDelete}
                rowCount={totalElements || 0}
                onHandlePagination={(pageNo: number, pageSize: number) => fetchBlogs({pageNo, pageSize})}                
                setTableRefresh={refreshTable}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default DashboardPosts;