import React, { InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  text?: string;
  form?: any;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      type = "text",
      sizeClass = "h-11 px-4 py-3",
      fontClass = "text-sm font-normal",
      rounded = "rounded-full",
      text = "",
      name = "",
      form,
      ...args
    },
    ref
  ) => {
    const fieldProps = (form && name) ? form?.getFieldProps(name) : {};
    const { error = "", touched = false }: any = name && form?.getFieldMeta && form?.getFieldMeta(name) || {};

    return (
      <label className="block md:col-span-2">
        {text && (
          <span className="text-neutral-800 dark:text-neutral-200">{text}</span>
        )}
        <input
          ref={ref}
          type={type}
          name={name}
          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200/50 bg-white dark:border-neutral-500 dark:focus:ring-primary-500/30 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className}`}
          {...fieldProps}
          {...args}
        />
        {touched && error && <p className="text-red-400 text-sm my-1">{error}</p>}
      </label>
    );
  }
);

export default Input;
