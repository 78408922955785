import { FC } from "react";
import { Helmet } from "react-helmet";
import LayoutPage from "components/LayoutPage/LayoutPage";

const PrivacyPolicy: FC = () => {

  return (
    <div className="">
       <Helmet>
        <title>Privacy Policy- DZ Insights</title>
        <meta name="title" content="DZ Insights Privacy Policy" />
        <meta name="description" content="Curious about your privacy? Dig the DZ Insights Privacy Policy to learn how we protect your data." />
      </Helmet>
      <LayoutPage
        subHeading=""
        headingEmoji="🔐"
        heading="Privacy Policy"
      >
       <div>
     <div className="">
      <h2 className="font-semibold flex items-center justify-center">Privacy Policy</h2>
      <br/>
      <p >
      This Privacy Policy governs the manner in which DZ Insights collects, uses, maintains, and discloses information collected from users (referred to as "you" or "users") of the www.dzinsights.com website (referred to as the "Website"). This Privacy Policy applies to the Website and all products and services offered by DZ Insights.
      </p>
      <br/>
      <h2 className="font-semibold font-style: italic">1. Information We Collect</h2>
      <br/>
      <p>
      We may collect personal identification information from users in various ways, including but not limited to when users visit our Website, register on the Website, place an order, subscribe to our newsletter, respond to a survey, fill out a form, and in connection with other activities, services, features, or resources we make available on our Website. Users may be asked for their name, email address, mailing address, phone number, and other relevant information as deemed necessary. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain website-related activities.
      </p>
      <br/>
      <h2 className="font-semibold font-style: italic">2. How We Use Collected Information</h2>
      <br/>
      <p>
      DZ Insights may collect and use users' personal information for the following purposes:
      </p>
      <br/>
      <li><span className="font-semibold">To improve customer service: </span>The information you provide helps us respond to your customer service requests and support needs more efficiently.</li><br/>
      <li><span className="font-semibold">To personalize user experience:</span> We may use information in the aggregate to understand how our users as a group use the services and resources provided on our Website.</li><br/>
      <li><span className="font-semibold">To improve our Website:</span> We continually strive to improve our Website offerings based on the information and feedback we receive from you.</li><br/>
      <li><span className="font-semibold">To process transactions:</span> We may use the information users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.</li> <br/>
      <li><span className="font-semibold">To send periodic emails:</span> We may use the email address to send users information and updates pertaining to their orders. It may also be used to respond to their inquiries,questions, and/or other requests. If users decide to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the user would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email or the user may contact us via our Website.</li><br/>
     
     <br/>
      <h2  className="font-semibold font-style: italic">3. Third-Party Access and Processing</h2>
      <br/>
      <p>
      We do not allow third-parties to access or process the data collected through the Website, except for third-party payment processors to securely process payments for services provided by DZ Insights.      </p>
      <br/>
      <h2  className="font-semibold font-style: italic">4. Children's Privacy</h2>
      <br/>
      <p>
      Although children may access the Website, it is not specifically directed at them. We do not knowingly collect personal information from individuals under the age of 13. If you believe that we have inadvertently collected personal information from a child, please contact us immediately, and we will take steps to remove the information from our systems.
      </p>
      <br/>
      <h2  className=" font-semibold font-style: italic">5. Security</h2>
      <br/>
      <p>
      We implement reasonable security measures to protect the confidentiality, integrity, and availability of the information we collect. These measures are designed to prevent unauthorized access, disclosure, alteration, or destruction of your personal information.      </p>
      <br/>
      <h2  className="font-semibold font-style: italic">6. User Rights</h2>
      <br/>
      <p>
      We respect your rights regarding your personal information. If you would like to access, rectify, erase, or object to the processing of your data, please contact us using the information provided at the end of this Privacy Policy. We will respond to your request in accordance with applicable laws and regulations.
      </p>
      <br/>
      <h2  className="font-semibold font-style: italic">7. How We Protect Your Information</h2>
      <br/>
      <p>
      We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Website.      </p>
      <br/>
      <h2  className="font-semibold font-style: italic">8. Sharing Your Personal Information</h2>
      <br/>
      <p>
      We do not sell, trade, or rent users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.      </p>
      <br/>
      <h2  className="font-semibold font-style: italic">9. Changes to This Privacy Policy</h2>
      <br/>
      <p>
      DZ Insights has the discretion to update this Privacy Policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of modifications.      </p>
      <br/>
      <h2 className="font-semibold font-style: italic">10. Your Acceptance of These Terms</h2>
      <br/>
      <p>
      By using this Website, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not use our Website. Your continued use of the Website following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.      </p>
      <br/>
      <h2   className="font-semibold font-style: italic">11. Contacting Us</h2>
      <br/>
      <p>
      If you have any questions about this Privacy Policy, the practices of this Website, or your dealings with this Website, please contact us at:      </p>
      <br/>
    <p className="text-blue-600"><a aria-label="dz insights website" href="https://www.dzinsights.com">https://www.dzinsights.com</a></p>
    <p className="text-blue-600"><a aria-label="contact dz insights" href="mailto:info@dzinsights.com">info@dzinsights.com</a></p>
    <p>C-29, Q Block, Mansarovar Extension, Narayan Vihar, Jaipur, Rajasthan 302029</p>
    </div>
     </div>
   
      </LayoutPage>
    </div>
  );
};

export default PrivacyPolicy;
