
import { FC ,useState} from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import {
  ButtonPrimary,
  Input,
  Alert,
  NcModal,
} from "components";

import { useAppDispatch } from "app/hooks";
import { resetPwdSchema as validationSchema } from "utils/validation";
import { changeUserPassword } from "app/auth/authSlice";
import { ChangePassword } from "types/Auth";

const DashboardUpdatePassword: FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [error, setError] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const initialValues: ChangePassword = {
    oldPassword: "",
    newPassword: ""
  };

  const onSubmit = (values: ChangePassword) => {
    setLoader(true);  
    dispatch(changeUserPassword(values))
      .unwrap()
      .then(() => setSuccess(true))
      .catch((err) => setError(err?.message || "Something wrong, Please try again."))
      .finally(() => setLoader(false))
  
  };
  const formicForm = useFormik({ enableReinitialize: true, initialValues, validationSchema, onSubmit });

  return (
    <div>
      <NcModal
        isOpenProp={success}
        modalTitle="Congratulations"
        contentExtraClass="max-w-screen-sm"
        renderTrigger={() => null}
        onCloseModal={() => {
          setSuccess(false);
          history.push("/dashboard");
        }}
        renderContent={() => ("Password updated successfully.")}
      />
        {error && <Alert type="error" children={error} onClose={() => setError("")} />}
        <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
         <div className="grid grid-cols-1 gap-6">
            <Input
              disabled={loader}
              form={formicForm}
              placeholder="******"
              type="password"
              name="oldPassword"
              text="Old Password"
              className="mt-1"
            />
            <Input
              disabled={loader}
              form={formicForm}
              placeholder="********"
              name="newPassword"
              type="password"
              text="New Password"
              className="mt-1"
            />
            <Input
              disabled={loader}
              form={formicForm}
              placeholder="********"
              name="confirmPassword"
              type="password"
              text="Confirm Password"
              className="mt-1"
            />
            <ButtonPrimary
              type="button"
              onClick={formicForm?.handleSubmit}
              loading={loader}
              disabled={loader}
            >
              Submit
            </ButtonPrimary>
          </div>
          </div>
    </div>
  );
};

export default DashboardUpdatePassword;
