import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { getNotifications, updateNotificationStatus } from "app/blogs/blogs";
import { NotificationsResponse, Pageable } from "types/Blog";
import Avatar from "components/Avatar/Avatar";
import { timeAgo } from "utils";
import Alert from "components/Alert/Alert";

export default function NotifyDropdown() {
  const [page, setPage] = useState<Pageable>({
    pageNo: 0,
    pageSize: 20,
  });
  const [data, setData] = useState<Array<NotificationsResponse>>([])
  const [totalCount,setTotalCount] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(40);
  const [hide,setHide] =useState<boolean>(false)
  const [error, setError] = useState<string>("")
  
const handleNotificationStatus =(id:string) => {
  updateNotificationStatus(id)
.then(()=>{  
  getNotificationsData(page)
})
.catch((err) =>{
  setError(err?.message || "Something wrong, Please try again.");  
})}
const handleViewMoreData = () => {
  let size = {
    pageNo:0,
    pageSize:currentPageSize
  }
  setCurrentPageSize(currentPageSize+20)
  if(currentPageSize) {
    getNotificationsData(size)
  }
  
}
  const getNotificationsData = (page: Pageable) =>{ 
  getNotifications(page)
  .then((res)=>{
    setData(res.content)
    setTotalCount(res.totalElements)
    {totalCount === res.numberOfElements && setHide(true)}
  }) 
  .catch((error)=>{
    setError(error?.message || "Something wrong, Please try again.")
  })
  }
useEffect(()=>{
  getNotificationsData(page)
},[])


  return (
    <div className="">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-90"}
                 group  p-3 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full inline-flex items-center text-base font-medium hover:text-opacity-100
                  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
              onClick={()=>{getNotificationsData(page)}}  
              >
    {data?.map((i)=>(!i.read && 
    <span className="absolute right-1 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-blue-500"></span>))}
              <svg width="28" height="30" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12 6.43994V9.76994"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
                <path
                  d="M12.02 2C8.34002 2 5.36002 4.98 5.36002 8.66V10.76C5.36002 11.44 5.08002 12.46 4.73002 13.04L3.46002 15.16C2.68002 16.47 3.22002 17.93 4.66002 18.41C9.44002 20 14.61 20 19.39 18.41C20.74 17.96 21.32 16.38 20.59 15.16L19.32 13.04C18.97 12.46 18.69 11.43 18.69 10.76V8.66C18.68 5 15.68 2 12.02 2Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
                <path
                  d="M15.33 18.8201C15.33 20.6501 13.83 22.1501 12 22.1501C11.09 22.1501 10.25 21.7701 9.65004 21.1701C9.05004 20.5701 8.67004 19.7301 8.67004 18.8201"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                />
              </svg>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
            <Popover.Panel className="absolute z-10 w-screen max-w-xs sm:max-w-sm px-4 mt-3 -right-28 sm:right-0 sm:px-0">
                <div className="overflow-y-auto  rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 notification-section">
                  <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7">
                    <h3 className="text-xl font-semibold">Notifications</h3>
                    {!data.length && <span className="font-semibold">Nothing we found!</span>}
                    {error && <Alert type="error" children={error} onClose={() => setError("")} />}
                    {data.map((item, index) => (
                      <a
                        key={index}
                        // href={item.href}
                        className="flex p-2 pr-8 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 relative 	cursor-pointer"
                      >
                        <Avatar
                          sizeClass="w-8 h-8 sm:w-12 sm:h-12"
                          radius="rounded-full"
                          imgUrl={item.details.from.avatar.thumbnail}
                        />
                        <div className="ml-3 sm:ml-4 space-y-1"  onClick={()=>handleNotificationStatus(item.id)}>
                          <p className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          {item.details.from.firstName +' '+ item.details.from.lastName}
                          </p>
                          <p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                            {item.text}
                          </p>
                          <p className="text-xs text-gray-400 dark:text-gray-400">
                            {timeAgo(item.createAt)}
                          </p>
                        </div>
                        {!item.read?<span className="absolute right-1 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-blue-500"></span>
                        :null}
                      </a>
                    ))}
                  {totalCount>20 && !hide ? (<button className="text-sm font-medium text-blue-900 cursor-pointer float-right" onClick={()=>handleViewMoreData()} aria-label="view more notifications">View More</button>) : null}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      </div>
  );
}
