import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { loginSchema as validationSchema } from "utils/validation";
import {UpdateUserPassword} from "types/Auth";
import {selectAuthState, updateUserPassword} from "app/auth/authSlice";
import {
  ButtonPrimary,
  Input,
  Alert,
} from "components";
import {useAppSelector} from "../../app/hooks";
interface FirstLoginProps {
    onClosedReceive: () => void;
}
const FirstLogin: React.FC<FirstLoginProps> = (props) => {
    const {user} = useAppSelector(selectAuthState)
    const history = useHistory();
    const [error, setError] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);

    const initialValues: UpdateUserPassword = {
        email: user?.email ? user.email:'',
        password: "",
        id: user?.id
    };

    const onSubmit = (values: UpdateUserPassword) => {
        setLoader(true);
        updateUserPassword(values)
            .then((res)=>{
                history.push('/')
            })
            .catch((err) => setError(err?.message || "Something wrong, Please try again."))
            .finally(() => setLoader(false))
    };
    const formicForm = useFormik({ enableReinitialize: true, initialValues, validationSchema, onSubmit });
    return (
        <div className={`nc-PageLogin`} data-nc-id="PageLogin">
            {error && <Alert type="error" children={error} onClose={() => setError("")} />}
            <div className="max-w-md mx-auto space-y-6">
                {/* FORM */}
                <div className="grid grid-cols-1 gap-6">
                    {!user?.email &&
                        <Input
                            disabled={loader}
                            form={formicForm}
                            placeholder="guest@example.com"
                            name="email"
                            text="Email"
                            className="mt-1"

                        />
                    }
                    <Input
                        disabled={loader}
                        form={formicForm}
                        placeholder="********"
                        name="password"
                        type="password"
                        text="Password"
                        className="mt-1"
                    />
                    <ButtonPrimary
                        type="button"
                        onClick={formicForm?.handleSubmit}
                        loading={loader}
                        disabled={loader}
                        className='firstLogin'
                    >
                        Continue
                    </ButtonPrimary>
                </div>
                {/* ==== */}
            </div>
        </div>
    );
};

export default FirstLogin;
