import { FC, useEffect, useRef, useState } from "react";
import SocialsShare from "components/SocialsShare/SocialsShare";
import Avatar from "components/Avatar/Avatar";
import BookmarkContainer from "containers/BookmarkContainer/BookmarkContainer";
import PostCardLikeContainer from "containers/PostCardLikeContainer/PostCardLikeContainer";
import { SinglePageType } from "containers/PageSingle/PageSingle";
import { FeaturedVideo } from "@mui/icons-material";

export interface HeaderProps {
  data: SinglePageType
}

const SubHeader: FC<HeaderProps> = ({ data }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);

  const [isSingleHeaderShowing, setIsSingleHeaderShowing] = useState<boolean>(false);

  useEffect(() => {
    //  BECAUSE DIV HAVE TRANSITION 100ms
    window.addEventListener("scroll", handleShowHideSingleHeadeEvent);

    return () => {
      window.removeEventListener("scroll", handleShowHideSingleHeadeEvent);
    };
  }, []);

  const handleShowHideSingleHeadeEvent = () => {
    window.requestAnimationFrame(showHideSingleHeade);
  };

  const handleProgressIndicator = () => {
    const entryContent = document.querySelector(
      "#single-entry-content"
    ) as HTMLDivElement | null;

    if (!entryContent) {
      return;
    }

    const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
    let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    let scrolled = (winScroll / totalEntryH) * 100;
    if (!progressBarRef.current || scrolled > 140) {
      return;
    }

    scrolled = scrolled > 100 ? 100 : scrolled;

    progressBarRef.current.style.width = scrolled + "%";
  };

  const showHideSingleHeade = () => {
    handleProgressIndicator();
    // SHOW _ HIDE SINGLE DESC MENU
    let winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > 450) {
      setIsSingleHeaderShowing(true);
    } else {
      setIsSingleHeaderShowing(false);
    }
  };


  const renderSingleHeader = () => {
    if (!isSingleHeaderShowing) return null;
    const { title, author, id, bookmark ,href, featuredImage} = data;
    return (
      <div className="nc-SingleHeaderMenu dark relative py-4 bg-neutral-900 dark:bg-neutral-900">
        <div className="container">
          <div className="flex justify-end lg:justify-between">
            <div className="hidden lg:flex items-center mr-3">
              <Avatar
                imgUrl={author.avatar}
                userName={author.displayName}
                sizeClass="w-8 h-8 text-lg mr-2"
                radius="rounded-full"
              />
              <h3 className="ml-4 text-lg line-clamp-1 text-neutral-100">
                {title}
              </h3>
            </div>

            {/* ACTION */}
            <div className="flex items-center space-x-2 text-neutral-800 sm:space-x-3 dark:text-neutral-100">
              <PostCardLikeContainer postId={data.id} like={data.like} />
              <BookmarkContainer
                initBookmarked={bookmark.isBookmarked}
                postId={id}
              />
              <div className="border-l border-neutral-300 dark:border-neutral-700 h-6"></div>
              <SocialsShare
                className="flex space-x-2"
                itemClass="w-8 h-8 bg-neutral-100 text-lg dark:bg-neutral-800 dark:text-neutral-300"
              href={href}
              img ={featuredImage}
              />
            </div>
          </div>
        </div>
        <div className="absolute top-full left-0 w-full progress-container h-[5px] bg-neutral-300 overflow-hidden">
          <div
            ref={progressBarRef}
            className="progress-bar h-[5px] w-0 bg-teal-600"
          />
        </div>
      </div>
    );
  };


  return (
    <div
      className="nc-Header sticky w-full left-0 right-0 z-40 transition-all "
      style={{top: '100px'}}
      ref={containerRef}
    >
      {/* RENDER PROGESSBAR FOR SINGLE PAGE */}
      {renderSingleHeader()}
    </div>
  );
};

export default SubHeader;
