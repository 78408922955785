import React, { FC, useState } from "react";
import NcModal from "components/NcModal/NcModal";
import Tag from "components/Tag/Tag";
import { TaxonomyType } from "data/types";

export interface ModalTagsProps {
  tags: TaxonomyType[];
}

const ModalTags: FC<ModalTagsProps> = ({ tags }) => {
  const currentUrl = window.location.href
  const queryParams = new URL(currentUrl); 
  const searchParams = new URLSearchParams(queryParams.search);
  const tagId = searchParams.get("tag");
  const selectedTag = tags.find((i) => i.id === tagId)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleSelectTag = () => {
    setIsOpen(false)
  }
  const renderModalContent = () => {
    return (
      <div className="flex flex-wrap dark:text-neutral-200">
        {tags.map((tag) => (
          <Tag key={tag.id} tag={tag} className="mr-2 mb-2" closeModal={handleSelectTag}/>
        ))}
      </div>
    );
  };

  return (
    <div className="nc-ModalTags all-tags-section">
      <NcModal
        contentExtraClass="max-w-screen-md"
        triggerText={
          <span onClick={() => setIsOpen(true)}>
            {tagId === null ? 
            (<span className="hidden sm:inline">All Tags</span> ) :
            (selectedTag?.name)}            
          </span>
        }
        modalTitle="Discover other tags"
        renderContent={renderModalContent}
        isOpenProp={isOpen}
        onCloseModal={handleSelectTag}
      />
    </div>
  );
};

export default ModalTags;
