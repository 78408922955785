import { FC, useState } from "react";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import LayoutPage from "components/LayoutPage/LayoutPage";
import SocialsList from "components/SocialsList/SocialsList";
import Textarea from "components/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { ContactUsPayload } from "types/Auth";
import Button from "components/Button/Button";
import { contactUs } from "app/blogs/blogs";
import {userQuerySchema as validationSchema} from "utils/validation";
import { Alert, NcModal } from "components";
export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "C-29, Q Block, Mansarovar Extension, Narayan Vihar, Jaipur, Rajasthan 302029",
  },
  {
    title: "💌 EMAIL",
    desc: "info@dzinsights.com",
  },
  {
    title: "☎ PHONE",
    desc: "+91 - 966 003 7299",
  },
];

  type FormAction = {
    resetForm: () => void;
  };
const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const formikForm = useFormik<ContactUsPayload>({
    initialValues: {
      firstName:"",
      lastName:"",
      email: "",
      query: ""
    },
    validationSchema,
    onSubmit: async (values: ContactUsPayload,action: FormAction) => {
      setLoader(true);
      contactUs(values)
        .then((res)=>{
          setLoader(false);
          setSuccess(true);
          action?.resetForm()
        })
        .catch((err) => {
          setError(err?.message || "Something wrong, Please try again.");
          setLoader(false);
        })
    }
  });
  const { values,errors,touched, handleChange,handleSubmit  } = formikForm;
  return (
    <div className={`nc-PageContact ${className}`} data-nc-id="PageContact">
      <Helmet>
        <title>Contact- DZ Insights</title>
        <meta name="title" content="Contact DZ Insights Support" />
        <meta name="description" content="Need help? Share your query or concern with us through our contact form and we will get back to you soon!" />        
      </Helmet>
      <LayoutPage
        subHeading="Drop us a message and we will get back to you."
        headingEmoji=""
        heading="Contact us"
      >
            <NcModal
        isOpenProp={success}
        modalTitle="Congratulations"
        contentExtraClass="max-w-screen-sm"
        renderTrigger={() => null}
        onCloseModal={() => {
          setSuccess(false);
        }}
        renderContent={() => ("Your Query has been submitted. We will get back to you soon!!")}
      />
      {error && <Alert type="error" children={error} onClose={() => setError("")} />}
        <div className="grid gap-8 lg:grid-cols-2">
          <div className="max-w-sm space-y-6">
            {info.map((item, index) => (
              <div key={index}>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  {item.title}
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  {item.desc}
                </span>
              </div>
            ))}
            <div>
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                🌏 SOCIALS
              </h3>
              <SocialsList className="mt-2" />
            </div>
          </div>
          <div className="border border-neutral-100 dark:border-neutral-700 lg:hidden"></div>
          <div>
            <form className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block">
                <Label>First name*</Label>
                <Input 
                placeholder="Example John"
                type="text"
                onChange={handleChange}
                className="mt-1"
                value={values.firstName}
                name="firstName"
                form={formikForm}
                />
              </label>
              <label className="block">
                <Label>Last name*</Label>
                <Input
                placeholder="Example Doe"
                type="text"
                onChange={handleChange}
                className="mt-1"
                name= "lastName"
                value={values.lastName}
                form={formikForm}
                />
              </label>
              <label className="block">
                <Label>Email address*</Label>
                <Input
                type="email"
                form={formikForm}
                placeholder="JohnDoe@gmail.com"
                className="mt-1"
                value={values.email} 
                name="email"
                onChange={handleChange}
                />
              </label>
              <label className="block">
                <Label>Message*</Label>
                <Textarea 
                rows={4}
                className="mt-1"
                name="query"
                placeholder="Write down your query"
                value={values.query}
                onChange={handleChange}
                />
             {touched.query && errors.query
            ? <p className="text-red-400 text-sm my-1">{errors.query}</p>
            : null}
              </label>
              <Button
              type="submit"
              className ="bg-[#419AAB] text-white"
              onClick={handleSubmit}
              loading={loader}
              >
              Send Message
            </Button>
            </form>
          </div>
        </div>
      </LayoutPage>
    </div>
  );
};

export default PageContact;
