import React, { useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectAuthState, updateProfile } from "app/auth/authSlice";
import { updateUserSchema as validationSchema } from "utils/validation";
import {
  Alert,
  ButtonPrimary,
  Input,
  NcModal,
} from "components";
import Label from "components/Label/Label";
import { UpdateUserPayload } from "types/Auth";
import { useDropzone } from "react-dropzone";

const DashboardEditProfile: React.FC<{}> = () => {
  const { user } = useAppSelector(selectAuthState);
  const initialValues: UpdateUserPayload = {
    id: user?.id || "",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    authorBio: user?.authorBio || "",
    website: user?.website || "",
    facebookProfileUrl: user?.facebookProfileUrl || "",
    linkedinProfileUrl: user?.linkedinProfileUrl || "",
    twitterProfileUrl: user?.twitterProfileUrl || "",
    youtubeProfileUrl: user?.youtubeProfileUrl || "",
  };

  const dispatch = useAppDispatch();
  const history = useHistory();
  const [profilePic, setProfilePic] = useState<File | string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const handleImageChange = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setProfilePic(file || "");
  };
  
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: handleImageChange,
    multiple: false, // Allow only one file to be dropped
  });  

  const onSubmit = (values: UpdateUserPayload, action: any) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("avatar", profilePic);
    formData.append("userUpdateRequest", JSON.stringify(values));
    dispatch(updateProfile(formData))
      .unwrap()
      .then(() => {
        setSuccess(true);
        setProfilePic("");
        setLoader(false);
      })
      .catch((e) => {
        setError(e?.message || "Something wrong, Please try again.");
        setLoader(false);
      });
  };

  const formicForm = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
      <NcModal
        isOpenProp={success}
        modalTitle="Congratulations"
        contentExtraClass="max-w-screen-sm"
        renderTrigger={() => null}
        onCloseModal={() => {
          setSuccess(false);
          history.push("/dashboard/posts");
        }}
        renderContent={() => ("Profile updated successfully.")}
      />
      {error && <Alert children={error} type="error" onClose={() => setError("")} />}
      <div className="grid md:grid-cols-2 gap-6">
        <label className="block md:col-span-2 mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">Upload Profile Pic
  <div className="w-210 mt-1 items-center dark:bg-neutral-900 flex flex-row px-4 py-3 border-r-20 bg-white text-blue rounded-lg tracking-wide uppercase border cursor-pointer img-upload">
    <svg className="w-5 h-5 mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path 
     d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"/>
  </svg>
  <div 
  {...getRootProps()}>
    <input
    {...getInputProps()}
   className="focus:outline-none focus:ring-none w-11/12"
   disabled={loader}
   />
    {acceptedFiles.length > 0 ? (
      <div>{acceptedFiles[0].name}</div>
    ) : (
      <div>Drag and drop an image here, or click to select a file</div>
    )}
    </div>
    </div>
  </label>
        <label className="block">
          <Input
            form={formicForm}
            placeholder="Example Doe"
            text="First name" 
            className="mt-1"
            name="firstName"
            disabled={loader}
          />
        </label>
        <label className="block">
          <Input
            form={formicForm}
            placeholder="Doe"
            text="Last name" 
            className="mt-1"
            name="lastName"
            disabled={loader}
          />
        </label>

        <label className="block md:col-span-2">
          <Label> Author Bio</Label>
          <Input
            form={formicForm}
            placeholder="Add details about yourself"
            className="mt-1"
            name="authorBio"
            disabled={loader}
          />
        </label>
        <label className="block md:col-span-2">
          <Label> Website URL</Label>
          <Input
            form={formicForm}
            placeholder="https://..."
            className="mt-1"
            name="website"
            disabled={loader}
          />
        </label>
        <label className="block md:col-span-2">
          <Label> Linkedin Profile Url</Label>
          <Input
            form={formicForm}
            disabled={loader}
            placeholder="https://..."
            className="mt-1"
            name="linkedinProfileUrl"
          />
        </label>
        <label className="block md:col-span-2">
          <Label> Twitter Profile Url</Label>
          <Input
            form={formicForm}
            disabled={loader}
            placeholder="https://..."
            className="mt-1"
            name="twitterProfileUrl"
          />
        </label>
        <label className="block md:col-span-2">
          <Label> Youtube Profile Url</Label>
          <Input
            form={formicForm}
            disabled={loader}
            placeholder="https://..."
            className="mt-1"
            name="youtubeProfileUrl"
          />
        </label>
        <label className="block md:col-span-2">
          <Label> Facebook Profile Url</Label>
          <Input
            form={formicForm}
            disabled={loader}
            placeholder="https://..."
            className="mt-1"
            name="facebookProfileUrl"
          />
        </label>
        <ButtonPrimary
          className="md:col-span-2"
          loading={loader}
          disabled={loader}
          onClick={formicForm?.handleSubmit}
        >
          Update profile
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default DashboardEditProfile;
