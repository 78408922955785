import { TaxonomyType } from "data/types";
import React, { FC } from "react";
import { Link, useHistory } from "react-router-dom";
import { UrlTypes } from "types";
import { urlParamsToObject } from "utils";

export interface TagProps {
  className?: string;
  tag: TaxonomyType;
  hideCount?: boolean;
  closeModal?: () => void
}

const Tag: FC<TagProps> = ({ className = "", tag, hideCount = false, closeModal }) => {
  const history = useHistory()
  const url: UrlTypes = urlParamsToObject(history?.location.search) 
  
  const handleClick = (tag : TaxonomyType) => {
    if(closeModal) {
      closeModal()
    }
    let newUrl= `/discover?${tag.href.split('?')[1]}`
    if(url.category && url.category != '' && url.sort && url.sort !== '') {
      newUrl= `discover?tag=${url.category}&sort=${url.sort}&${tag.href.split('?')[1]}`
      return history.push(newUrl)
    }else if(url.category && url.category != '') {
      newUrl= `discover?category=${url.category}&${tag.href.split('?')[1]}`
      history.push(newUrl)
    }else if(url.sort && url.sort != '') {
      newUrl= `discover?sort=${url.sort}&${tag.href.split('?')[1]}`
      history.push(newUrl)
    }else {
      history.push(newUrl)
    }
  }

  return (
    <div
      className={`nc-Tag inline-block cursor-pointer bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 px-3 rounded-lg border border-neutral-100 md:py-2.5 md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 ${className}`}
      data-nc-id="Tag"
      onClick={()=>handleClick(tag)}
    >
      {`${tag.name}`}
      {!hideCount && (
        <span className="text-xs font-normal"> ({tag.count})</span>
      )}
    </div>
  );
};

export default Tag;
