import React, { FC } from "react";
import NcImage from "components/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { NavLink, useHistory } from "react-router-dom";
import { urlParamsToObject } from "utils";
import { UrlTypes } from "types";
export interface CardCategory1Props {
  className?: string;
  taxonomy: TaxonomyType;
  size?: "large" | "normal";
  closeModal?: () => void
}

const CardCategory1: FC<CardCategory1Props> = ({
  className = "",
  size = "normal",
  taxonomy,
  closeModal,
}) => {
  const { count, name, href = "/", thumbnail } = taxonomy;
  const history = useHistory()
  const url: UrlTypes = urlParamsToObject(history?.location.search) 

 const handleClick = (href:string) => {
  if(closeModal) {
    closeModal()
  }
    let newUrl= `/discover?${href.split('?')[1]}`
    if(url.tag && url.tag != '' && url.sort && url.sort !== '') {
      newUrl= `discover?tag=${url.tag}&sort=${url.sort}&${href.split('?')[1]}`
      return history.push(newUrl)
    }else if(url.tag && url.tag != '') {
      newUrl= `discover?tag=${url.tag}&${href.split('?')[1]}`
      return history.push(newUrl)
    }else if(url.sort && url.sort != '') {
      newUrl= `discover?sort=${url.tag}&${href.split('?')[1]}`
      return history.push(newUrl)
    }else {
      history.push(newUrl)
    }
 }

  return (
    <div
      onClick={() => handleClick(href)}
      className={`nc-CardCategory1 cursor-pointer flex items-center ${className}`}
      data-nc-id="CardCategory1"
    >
      <NcImage
        containerClassName={`flex-shrink-0 ${
          size === "large" ? "w-20 h-20" : "w-12 h-12"
        } rounded-lg mr-4 overflow-hidden`}
        src={thumbnail}
      />
      <div>
        <h2
          className={`${
            size === "large" ? "text-lg" : "text-base"
          } nc-card-title text-neutral-900 dark:text-neutral-100 font-semibold`}
        >
          {name}
        </h2>
        <span
          className={`${
            size === "large" ? "text-sm" : "text-xs"
          } block mt-[2px] text-neutral-500 dark:text-neutral-400`}
        >
          {count} Articles
        </span>
      </div>
    </div>
  );
};

export default CardCategory1;
