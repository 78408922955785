import { PostAuthorType } from "data/types";
import {AuthorResponseType} from "../../types/Blog";
import {SocialType} from "data/types";
import TwitterIcon from "../../components/SocialsList/Twitter";

export class AuthorDetailConvertor implements PostAuthorType {
    id: string | number;
    firstName: string;
    lastName: string;
    displayName: string;
    avatar: string;
    bgImage?: string | undefined;
    email?: string | undefined;
    count: number;
    desc: string;
    jobName: string;
    href: string;
    authorBio: string;
    socialLink: SocialType[] | undefined;

    constructor (data: AuthorResponseType) {
        this.id = data.id;
        this.firstName = data.firstName;
        this.email = data.email;
        this.lastName = data.lastName;
        this.avatar = data.avatar.thumbnail;
        this.displayName = data.firstName + ' ' + data.lastName;
        this.count = 0;
        this.desc = data.authorBio;
        this.jobName = "";
        this.href = `/author/${data.id}`;
        this.socialLink = [
            { id: "Facebook", name: "Facebook", icon: "lab la-facebook-square", href: `${data?.facebookProfileUrl}` },
            { id: "Twitter", name: "Twitter", icon: "lab la-twitter", href: `${data?.twitterProfileUrl}`, image: TwitterIcon},
            { id: "LinkedIn", name: "LinkedIn", icon: "lab la-linkedin", href: `${data?.linkedinProfileUrl}` },
            { id: "Youtube", name: "Youtube", icon: "lab la-youtube", href: `${data?.youtubeProfileUrl}` },
            { id: "Website", name: "Website", icon: "lab la-weebly", href: `${data?.website}`},
        ];
        this.authorBio = data.authorBio;
    }
}