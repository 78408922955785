import { PostAuthorType } from "data/types";
import { CreatedByType } from "types/Auth";
import avatar16 from "data/avatars/7309681.jpg";

export class Author implements PostAuthorType {
    id: string | number;
    firstName: string;
    lastName: string;
    displayName: string;
    avatar: string;
    bgImage?: string | undefined;
    email?: string | undefined;
    count: number;
    desc: string;
    jobName: string;
    href: string;

    constructor (createdBy: CreatedByType) {
        this.id = createdBy.id;
        this.firstName = createdBy.firstName;
        this.lastName = createdBy.lastName;
        this.avatar = createdBy?.avatar?.thumbnail || avatar16;
        this.displayName = createdBy.firstName + ' ' + createdBy.lastName;
        this.count = 0;
        this.desc = "";
        this.jobName = "";
        this.href = `/author/${createdBy.id}`;
    }

}