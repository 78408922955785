import {
    GoogleIcon,
    LinkedInIcon,
} from "images";
import TwitterIcon from "../components/SocialsList/Twitter";
export enum Status {
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    ACTIVE = "ACTIVE"
};

export enum AccessPlatform {
  INSIGHTS = "INSIGHTS"
}

const getGoogleUrl = () => {
  const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;
  const options = {
    redirect_uri: import.meta.env.VITE_GREDIRECT_URL,
    client_id: import.meta.env.VITE_GCLIENT_ID,
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    state: 'GOOGLE',
    scope: [
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
    ].join(' ')
  };

  const qs = new URLSearchParams(options);
  return `${rootUrl}?${qs.toString()}`;
};

const getLinkedInUrl = () => {
  const rootUrl = `https://www.linkedin.com/oauth/v2/authorization`;
  const options = {
    redirect_uri: import.meta.env.VITE_GREDIRECT_URL,
    client_id: import.meta.env.VITE_LINKED_ID,
    response_type: 'code',
    state: 'LINKEDIN',
    scope: 'r_liteprofile r_emailaddress'
  };

  const qs = new URLSearchParams(options);
  return `${rootUrl}?${qs.toString()}`;
};

export const SOCIAL_LOGIN_LIST = [
    {  
      name: "Continue with Google",
      icon: GoogleIcon,
      link: getGoogleUrl(),
    },
    {
      name: "Continue with Twitter",
      image: TwitterIcon,
      link: `${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/auth/social/twitter`
    },
    {  
      name: "Continue with LinkedIn",
      icon: LinkedInIcon,
      link: getLinkedInUrl(),
    },
];