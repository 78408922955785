import React, { useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import {
  ButtonCircle,
  NcImage,
  Badge,
  Input,
  Alert,
  NcModal
} from "components";
import { SubcribeIcon } from "images";
import { useAppDispatch } from "app/hooks";
import { SubscribePayload } from "types/Auth";
import { subscribeSchema as validationSchema } from "utils/validation";
import { subscribe } from "app/auth/authSlice";

const SectionSubscribe2: React.FC<{className?: string}> = ({className = ""}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const initialValues: SubscribePayload = {
    email: "",
  };

  const onSubmit = (values: SubscribePayload, action: any) => {
    dispatch(subscribe(values))
      .unwrap()
      .then(() => {
        setSuccess(true)
        action?.resetForm()
        setError("")})
      .catch((err) => setError(err?.message || "Something wrong, Please try again."))
      action?.resetForm()
  };
  
  const formicForm = useFormik({ enableReinitialize: true, initialValues, validationSchema, onSubmit });
 
  return (
      <div
        className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row items-center ${className}`}
        data-nc-id="SectionSubscribe2"
      >
         <NcModal
        isOpenProp={success}
        modalTitle="Congratulations"
        contentExtraClass="max-w-screen-sm"
        renderTrigger={() => null}
        onCloseModal={() => {
          setSuccess(false);
          history.push("/");    
        }}
        renderContent={() => ("Thanks for subscribing. You will be start receiving our newsletter on your email")}
      />
        
        <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
          <h2 className="font-semibold text-4xl">Join our newsletter 🎉</h2>
          <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
            Read and share new perspectives on just about any topic. Everyone’s
            welcome.
          </span>
          {/* <ul className="space-y-5 mt-10">
            <li className="flex items-center space-x-4">
              <Badge name="01"/>
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get more discount
            </span>
            </li>
            <li className="flex items-center space-x-4">
              <Badge color="red" name="02"/>
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get premium magazines
            </span>
            </li>
          </ul> */}
          <div className="mt-10 relative max-w-sm subscribe-section">
            <Input
              form={formicForm}
              placeholder="Enter your email"
              className="mt-1"
              name="email"
            />
            {error && error ? (<p className="text-red-600 text-sm">{error}</p>) : null}
            <ButtonCircle
              onClick={() => formicForm?.handleSubmit()}
              className="absolute transform top-[22px] -translate-y-1/2 right-1"
              aria-label="circular arrow right"
            >
              <i className="las la-arrow-right text-xl"></i>
            </ButtonCircle>
          </div>
        </div>
        <div className="flex-grow">
          <NcImage src={SubcribeIcon}/>
        </div>
      </div>
  );
};

export default SectionSubscribe2;
