import React, { FC, useEffect, useRef, useState } from "react";
import NcModal from "components/NcModal/NcModal";
import SingleCommentForm from "containers/PageSingle/SingleCommentForm";
import { CommentType } from "./CommentCard";
import { updateBlogComment } from "app/blogs/blogs";
import Alert from "components/Alert/Alert";
export interface ModalEditCommentProps {
  comment: CommentType;
  show: boolean;
  onCloseModalEditComment: () => void;
}

const ModalEditComment: FC<ModalEditCommentProps> = ({
  comment,
  show,
  onCloseModalEditComment,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement|null>(null);
  const [error, setError] =  useState<string>('')

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const element: HTMLTextAreaElement | null = textareaRef.current;
        if (element) {
          (element as HTMLTextAreaElement).focus();
          (element as HTMLTextAreaElement).setSelectionRange(
            (element as HTMLTextAreaElement).value.length,
            (element as HTMLTextAreaElement).value.length
          );
        }
      }, 400);
    }
  }, [show]);
  
// Handle Edit Function
function handleEdit(){
  const payload = {
    content : textareaRef?.current?.value || "",
    id:comment.id
  }

  updateBlogComment(payload)
  .then((res) =>{
    onCloseModalEditComment()
  })
  .catch((err) =>{
    onCloseModalEditComment()
    setError(err?.message || "Something wrong, Please try again.")
  })
}
  const renderContent = () => {
  
    return (
     
     
        <SingleCommentForm
          className="mt-0"
          onClickCancel={onCloseModalEditComment}
          onClickSubmit={handleEdit}
          defaultValue={comment.content}
          textareaRef={textareaRef}
          rows={8}
        />
  
      
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <>
       {error && <Alert type="error" children={error} onClose={() => setError("")} />}
       <NcModal
          isOpenProp={show}
          onCloseModal={onCloseModalEditComment}
          contentExtraClass="max-w-screen-md"
          renderContent={renderContent}
          renderTrigger={renderTrigger}
          modalTitle="Editing comment"
        />
    </>
    
  );
};

export default ModalEditComment;
