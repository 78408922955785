import { TaxonomyType } from "data/types";
import { CategoryResponse } from "types/Blog";
import { getRandomColor } from "utils";
export class CategoryConverter implements TaxonomyType {
  id: string | number;
  name: string;
  href: string;
  count?: number;
  thumbnail?: string;
  desc?: string;
  color?:  string;
  taxonomy: "category" | "tag";

 
    constructor(data: CategoryResponse ) {
      this.id = data.id
      this.name = data.name
      this.href = `/discover?category=${data.id}`
      this.thumbnail = data.image?.original
      this.taxonomy = "category"
      this.color = getRandomColor()
      this.count = data.totalCount,
      this.desc = ""         
    }
}