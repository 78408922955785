import ButtonPrimary from "components/Button/ButtonPrimary";
import { CustomLink } from "data/types";
import { FC, useState } from "react";
import { Pageable } from "types/Blog";
import twFocusClass from "utils/twFocusClass";

export interface AuthorPaginationProps {
  className?: string;
  onPageChange?: (page:Pageable) => void,
  totalPage?: number,
  pageSize?: number,
  onClick?:() => void,
}

const AuthorPagination: FC<AuthorPaginationProps> = ({ 
  className = "",
  onPageChange,
  totalPage = 0,
  pageSize = 10,
  }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageNo, setPageNo] = useState<number>(1)
  const handlePageChange = (page: number) => {
    setPageNo(page)
    setCurrentPage(page + 1);
    onPageChange?.({pageNo: page, pageSize: pageSize})
  }  
  const clickToShowMore = () =>{
    setCurrentPage(pageNo + 1);
    onPageChange?.({pageNo: pageNo, pageSize: pageSize*2})
  }
  const renderItem = (pag: CustomLink, index: number,state:any) => {
 
    if (index + 1 === currentPage) {
      // Return active pagination
      
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {pag.label}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
    <button
      key={index}
      onClick={(e) => {
        handlePageChange(index)
      }}
      aria-label={`show ${pag.label} page`}
      className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
    >
      {pag.label}
    </button>
    );
  };
  const generatePagination = () => {
    const pagination = [];
    const maxVisiblePages = 6;
    if(totalPage <= 1) {
      return null;
    }
    if(totalPage <= maxVisiblePages) {
      for (let i = 1; i <= totalPage; i++) {
        pagination.push({
          label: i.toString(),
          href: "#",
        });
      }
    }else {
      const visiblePageRange = Math.floor((maxVisiblePages - 3) / 2);
      pagination.push({
        label: "1",
        href: "#",
      });
      if (currentPage - visiblePageRange > 2) {
        pagination.push({
          label: "...",
          href: "#",
        });
      }
      const startPage = Math.max(2, currentPage - visiblePageRange);
      const endPage = Math.min(currentPage + visiblePageRange, totalPage - 1);
      for (let i = startPage; i <= endPage; i++) {
        pagination.push({
          label: i.toString(),
          href: "#",
        });
      }
      if (currentPage + visiblePageRange < totalPage - 1) {
        pagination.push({
          label: "...",
          href: "#",
        });
      }
      pagination.push({
        label: totalPage.toString(),
        href: "#",
      });
    }    
    return pagination;
  };

  const paginationItems = generatePagination();
  const isLastPage = currentPage === totalPage;
  if (!paginationItems) {
    return null; 
  }
  return (  
    <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center"> 
      <nav
        className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
      >
        {paginationItems.map(renderItem)}
      </nav>
      <ButtonPrimary disabled={isLastPage} onClick={clickToShowMore}>Show me more</ButtonPrimary>
    </div>
  );
};

export default AuthorPagination;
