import React, { FC, useState } from "react";
import { TaxonomyType } from "data/types";
import CardCategory1 from "components/CardCategory1/CardCategory1";
import NcModal from "components/NcModal/NcModal";

export interface ModalCategoriesProps {
  categories: TaxonomyType[];
}

const ModalCategories: FC<ModalCategoriesProps> = ({ categories }) => {
  const currentUrl = window.location.href
  const queryParams = new URL(currentUrl); 
  const searchParams = new URLSearchParams(queryParams.search);
  const categoryId = searchParams.get("category");
  const selectedCategory = categories.find((i) => i.id === categoryId)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleCategorySelect = () => {
    setIsOpen(false)
  }
  const renderModalContent = () => {
    return (
      <div className="grid gap-6 sm:grid-cols-2 sm:py-2 md:gap-8 md:grid-cols-3 lg:grid-cols-4 xl:md:grid-cols-5">
        {categories.map((cat) => (
          <CardCategory1 key={cat.id} taxonomy={cat} size="normal" closeModal={handleCategorySelect}/>
        ))}
      </div>
    );
  };
  return (
    <div className="nc-ModalCategories all-category-section">
      <NcModal
        triggerText={
          <span onClick={() => setIsOpen(true)}>
            { categoryId === null ? 
            ( <span className="hidden sm:inline">All Categories</span> )
            : ( selectedCategory?.name )}                   
          </span>
        }
        modalTitle="Discover other categories"
        renderContent={renderModalContent}
        isOpenProp={isOpen}
        onCloseModal={handleCategorySelect}
      />
    </div>
  );
};

export default ModalCategories;
