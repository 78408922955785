import React, { FC, useEffect, useRef, useState } from "react";
import NcModal from "components/NcModal/NcModal";
import { CommentType } from "./CommentCard";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";
import { deleteBlogComment } from "app/blogs/blogs";
import Alert from "components/Alert/Alert";
export interface ModalDeleteCommentProps {
  commentId: CommentType["id"];
  show: boolean;
  onCloseModalDeleteComment: () => void;
}

const ModalDeleteComment: FC<ModalDeleteCommentProps> = ({
  commentId,
  show,
  onCloseModalDeleteComment,
}) => {
  const textareaRef = useRef(null);
  const [error, setError] =  useState<string>('')

  const handleClickSubmitForm = () => {
    deleteBlogComment(commentId)
    .then((res) =>{
      onCloseModalDeleteComment();
    })
    .catch((err) =>{
      onCloseModalDeleteComment();
      setError(err?.message || "Something wrong, Please try again.");  
    })
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const element: HTMLTextAreaElement | null = textareaRef.current;
        if (element) {
          (element as HTMLTextAreaElement).focus();
        }
      }, 400);
    }
  }, [show]);

  const renderContent = () => {
    return (
      <>
      <form action="#">
        <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
          Delete comment
        </h3>
        <span className="text-sm">
          Are you sure you want to delete this comment? You cannot undo this
          action.
        </span>
        <div className="mt-4 space-x-3">
          <ButtonPrimary onClick={handleClickSubmitForm} type="button">
            Delete
          </ButtonPrimary>
          <ButtonSecondary type="button" onClick={onCloseModalDeleteComment}>
            Cancel
          </ButtonSecondary>
        </div>
      </form>
      </>
      );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <>
   {error && <Alert type="error" children={error} onClose={() => setError("")} />}
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalDeleteComment}
      contentExtraClass="max-w-screen-sm"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle=""
    />
    </>
  );
};

export default ModalDeleteComment;
