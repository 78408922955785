import { CategoryResponse } from "types/Blog";

// Date Converter
export const dateConverter = function (publishedDate: string) {
    if (!publishedDate) return "";
    const date = new Date(publishedDate);
    const formattedDate = `${date.toLocaleString("en-US", {
      month: "long",
    })} ${date.getDate()}, ${date.getFullYear()}`;
  
    return formattedDate;
  };
//Url search Params
export function urlParamsToObject(urlSearchString: string = ""): { [key: string]: string } {
  return (
    decodeURI(urlSearchString)
      .replace("?", "")
      .split("&")
      ?.map((param) => param.split("="))
      ?.reduce((values:{[key: string]: string}, [key, value]) => {
        values[key] = value
        return values;
      }, {}) || {}
  );
}
export function getRandomColor() {
  const colorNames = ["pink", "green", "yellow", "red", "indigo", "blue", "purple", "gray"];
  const randomIndex = Math.floor(Math.random() * colorNames.length);
  const randomColor = colorNames[randomIndex]
  return randomColor
}

export const timeAgo = (date: string | number | Date) => {
  const time = Math.floor(
    (new Date().valueOf() - new Date(date).valueOf()) / 1000
  );
  const { interval, unit } = calculateTimeDifference(time);
  const suffix = interval === 1 ? '' : 's';
  return `${interval} ${unit}${suffix} ago`;
};
const calculateTimeDifference = (time: number) => {
  const units = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'week', seconds: 604800 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];
  for (let { label, seconds } of units) {
    const interval = Math.floor(time / seconds);
    if (interval >= 1) {
      return {
        interval: interval,
        unit: label
      };
    }
  }
  return {
    interval: 0,
    unit: ''
  };
};

//Get Bg Image

export function getBgImage(){
  const bgImages = [
   "https://images.pexels.com/photos/912410/pexels-photo-912410.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/7354542/pexels-photo-7354542.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/3651577/pexels-photo-3651577.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/3330118/pexels-photo-3330118.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/4066850/pexels-photo-4066850.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/931887/pexels-photo-931887.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/7175377/pexels-photo-7175377.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/7663205/pexels-photo-7663205.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
   "https://images.pexels.com/photos/973505/pexels-photo-973505.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
    ];
    const randomIndex = Math.floor(Math.random() * bgImages.length);
    const randomImage = bgImages[randomIndex];
    return randomImage

}