import { FC } from "react";
import MainNav2Logged from "./MainNav2Logged";
import MainNav2 from "./MainNav2";
import MainNav1 from "./MainNav1";
export interface HeaderProps {
  mainNavStyle?: "style1" | "style2" | "style2Logedin";
  isTopOfPage?: boolean;
}

const Header: FC<HeaderProps> = ({ mainNavStyle = "style1", isTopOfPage }) => {

  const renderMainNav = () => {
    switch (mainNavStyle) {
      case "style1":
        return <MainNav1 />;
      case "style2":
        return <MainNav2 />;
      case "style2Logedin":
        return <MainNav2Logged />;

      default:
        return <MainNav1 />;
    }
  };

  return (
    <div
      className="nc-Header sticky top-0 w-full left-0 right-0 z-40 transition-all "
    >
      {/* RENDER MAIN NAVIGATION */}
      <div
        className={`bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-b dark:border-neutral-700 ${
          isTopOfPage ? "!border-transparent" : ""
        }`}
      >
        {renderMainNav()}
      </div>
    </div>
  );
};

export default Header;
