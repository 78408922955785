import React, { FC } from "react";
import TwitterIcon from "../SocialsList/Twitter";

export interface SocialsShareProps {
  className?: string;
  itemClass?: string;
  href?:string;
  img?:string;
  isCard?:boolean;
}

export interface SocialType {
  id: string;
  name: string;
  icon: string;
  href: string;
  image?: any;
}

const socials: SocialType[] = [
  { id: "Facebook", name: "Facebook", icon: "lab la-facebook-f", href: "" },
  { id: "Twitter", name: "Twitter", icon: "lab la-twitter", image: TwitterIcon, href: "" },
  { id: "Linkedin", name: "Linkedin", icon: "lab la-linkedin-in", href: '' },
  { id: "Pinterest", name: "Pinterest", icon: "lab la-pinterest", href: "" },
];

export const SOCIALS_DATA = socials;

const SocialsShare: FC<SocialsShareProps> = ({
  className = "grid gap-[6px]",
  itemClass = "w-7 h-7 text-base hover:bg-neutral-100",
  href,
  img,
  isCard = false
}) =>  
  {
    const socialsWithCustomHref = socials.map((social) => {
      let base_Path = import.meta.env.VITE_BASE_PATH;
      if (base_Path === undefined) {
       base_Path = "";
      } else {
        base_Path =import.meta.env.VITE_BASE_PATH;
      }
      let url = new URL(window.location.href)
      if (url.origin === "https://www.dzinsights.com/") {
        href =  url.pathname.slice(1);
      }
      let updatedHref = social.href;
      if (social.id === "Linkedin") {
        updatedHref = `https://www.linkedin.com/shareArticle?url=${url.origin}${base_Path}${href}`;
      } else if (social.id === "Facebook") {
        updatedHref = `https://www.facebook.com/sharer/sharer.php?u=${url.origin}${base_Path}${href}`;
      } else if (social.id === "Twitter") {
        updatedHref = `https://twitter.com/share?url=${url.origin}${base_Path}${href}`;
      }
      else if (social.id === "Pinterest") {
        updatedHref = `https://pinterest.com/pin/create/button/?url=${url.origin}${base_Path}${href}&&media=${img}`;
      }
      return { ...social, href: updatedHref };
    });
    
  const renderItem = ({image: IconSvg, ...item}: SocialType, index: number) => {

    return (
      <a
        key={index}
        href={item.href}
        target="_blank"
        className={`rounded-full leading-none flex items-center justify-center bg-white text-neutral-6000 ${itemClass}`}
        title={`Share on ${item.name}`}
      >
          {IconSvg && <IconSvg fill={isCard ? "#4c5563" : "#fff"}/>}
          {!IconSvg && <i className={item.icon}></i>}
      </a>
    );
  };

  return (
    <div className={`nc-SocialsShare ${className}`} data-nc-id="SocialsShare">
      {socialsWithCustomHref.map(renderItem)}
    </div>
  );
};

export default SocialsShare;
