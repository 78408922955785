import { PostAuthorType } from "data/types";
import { CreatedByType } from "types/Auth";
import { PagableResponse } from "types/index";
import {  AuthorTypeResponse } from "types/Blog";
import { getBgImage } from "utils";
import avatar16 from "data/avatars/7309681.jpg";

export class AuthorConverter implements PostAuthorType {
    id: string | number;
    firstName: string;
    lastName: string;
    displayName: string;
    avatar: string;
    bgImage?: string | undefined;
    email?: string | undefined;
    count: number;
    desc: string;
    jobName: string;
    href: string;
  
      constructor (data:  AuthorTypeResponse) {
        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.avatar = data?.avatar?.thumbnail || avatar16;
        this.displayName = data.firstName + ' ' + data.lastName;
        this.count = data.postsCount ? data.postsCount : 0;
        this.desc = "";
        this.jobName = "";
        this.href = `/author/${data.id}`;
        this.bgImage = getBgImage()
    }

}