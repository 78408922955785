import { CommentType } from "components/CommentCard/CommentCard";
import { PostAuthorType } from "data/types";
import { CommentTypeResponse } from "types/Blog";
import { Author } from "./Author";
export class CommentConverter implements CommentType  {
    id: number;
  author: PostAuthorType;
  date: string;
  content: string;
  parentId: number | null;
  children?: CommentType[];
  like: {
    count: number;
    isLiked: boolean;
  };

    constructor(data : CommentTypeResponse) {
        this.id = data.id
        this.author= new Author(data.author)
        this.date = data.date
        this.content = data.content
        this.parentId = data.parentId
        this.children = []
        this.like={
            count :   0,
            isLiked : false
        }

    }
}