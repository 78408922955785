import React, { useEffect, useRef, useState, useCallback } from "react";
import Label from "components/Label/Label";
import Button from "components/Button/Button";
import { useAppSelector } from "app/hooks";
import { selectDarkmodeState } from "app/darkmode/darkmode";
import { useDropzone } from "react-dropzone";
export interface UploadImageProps {
    handleChange: (file: File) => void,
    image?: string | File,
    title?: string,
    error?: boolean,
}

const UploadImage: React.FC<UploadImageProps> = ({
    handleChange,
    image = "",
    title = "",
    error = false
  }) => {
    const darkmodeState = useAppSelector(selectDarkmodeState);
    const [file, setFile] = useState<File | null>(null);
    const fileRef = useRef<HTMLInputElement>(null);
    const [preview, setPreview] = useState<string | File>(image);
  
    useEffect(() => {
      setPreview(image)
    }, [image]);
  
    const onDrop = useCallback((acceptedFiles: File[]) => {
      const selectedFile = acceptedFiles[0];
      setFile(selectedFile);
      setPreview(URL.createObjectURL(selectedFile));
      handleChange(selectedFile);
    }, [handleChange]);
  
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      multiple: false
    });
    return (
        <div className="block md:col-span-2">
        <div className="flex justify-between">
          {title && <Label>{title}</Label>}
                {preview && (<>
        <Button type="button" onClick={() => fileRef?.current?.click()} sizeClass="px-1 py-0 sm:px-0">

            <div className="space-y-1 text-center dark">
                <svg fill={darkmodeState ? "#fff" : "#000"} height="25px" width="25px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 487 487" xmlSpace="preserve">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <g>
                            <g>
                                <path d="M308.1,277.95c0,35.7-28.9,64.6-64.6,64.6s-64.6-28.9-64.6-64.6s28.9-64.6,64.6-64.6S308.1,242.25,308.1,277.95z M440.3,116.05c25.8,0,46.7,20.9,46.7,46.7v122.4v103.8c0,27.5-22.3,49.8-49.8,49.8H49.8c-27.5,0-49.8-22.3-49.8-49.8v-103.9 v-122.3l0,0c0-25.8,20.9-46.7,46.7-46.7h93.4l4.4-18.6c6.7-28.8,32.4-49.2,62-49.2h74.1c29.6,0,55.3,20.4,62,49.2l4.3,18.6H440.3z M97.4,183.45c0-12.9-10.5-23.4-23.4-23.4c-13,0-23.5,10.5-23.5,23.4s10.5,23.4,23.4,23.4C86.9,206.95,97.4,196.45,97.4,183.45z M358.7,277.95c0-63.6-51.6-115.2-115.2-115.2s-115.2,51.6-115.2,115.2s51.6,115.2,115.2,115.2S358.7,341.55,358.7,277.95z">
                                </path>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </Button>
        <input
          {...getInputProps()}
            ref={fileRef}
            type="file"
            className="sr-only"
        />
                </>)}
        </div>
            <div className={`mt-1 flex justify-center px-6 pt-5 pb-6 ${preview ? "border-solid border border-neutral-300" : "border-2 border-neutral-300 border-dashed dark:border-neutral-700 "} rounded-md bg-cover bg-no-repeat bg-center`}
          style={{
            backgroundImage: `url(${preview})`,
            height: "140px",
            width: "100%"
          }}
          {...getRootProps()}
        >
          <input
            {...getInputProps()}
            type="file"
            className="sr-only"
          />
    
          {isDragActive ? (
            <p>Drop the file here...</p>
          ) : (
            <>
            {!preview && (
                      <div className="space-y-1 text-center">
                      <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                      >
                          <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                          ></path>
                      </svg>
                      <div className="flex flex-col sm:flex-row text-sm text-neutral-6000 justify-center">
                          <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                          >
                              <span>Drag and drop an image here, or click to select a file</span>
                          </label>
                      </div>
                  </div>
              )}
            </>
          )}
        </div>
        {error && (
          <p className="text-red-600 text-sm mt-2">Please select a banner image</p>
        )}
      </div>
    );
  };

export default UploadImage;
