import SectionHero from "components/SectionHero/SectionHero";
import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionWhatWeOffer from "./SectionWhatWeOffer";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>DZ Insights- Discover The World</title>
        <link rel = "canonical" href = {window.location.href} />
        <meta name="title" content="About DZ Insights" />
        <meta name="description" content="DZ Insights is a blogging platform where bloggers unite to elevate their content game and share insightful and fresh perspectives." />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re unbiased and autonomous, and every day we create exceptional, high-quality content that educates, informs, and amuses millions of readers around the world."
        />

        <SectionFounder />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionWhatWeOffer/>
        </div>
    
        <SectionSubscribe2 />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionStatistic />
        </div>
      </div>
    </div>
  );
};

export default PageAbout;
