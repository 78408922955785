import  { useEffect, useState } from "react";
import { SwitchProps, useHistory, useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { selectAuthState, setToken, setUser } from "app/auth/authSlice";
import { getCurrentUserDetail } from "app/blogs/blogs";
import { Alert } from "components";
const SessionSecurityWrapper = ({ children }:SwitchProps ) =>{
    const [error, setError] =  useState<boolean>(false)
    const dispatch = useAppDispatch();
    const { token } = useAppSelector(selectAuthState);
    const location = useLocation();
    const history = useHistory()

  useEffect(() => {
// if token is available then call checkSession to verify whether the session is expired or not
    token && checkSession();
  }, [location.pathname,location.search,location.key,location.state]);

//Check Session
  function checkSession(){
    getCurrentUserDetail()
    .then((res) => {
        dispatch(setUser(res))
    })
    .catch((err) => {
        setError(true)
        history.push("/login")   
        dispatch(setToken(""))
    });
  }

  return <>
    {error && <Alert type="error" children={"Session Expired"} onClose={() => setError(false)} />}
  {children}
  </>;
};

export default SessionSecurityWrapper;
