import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual } from "react-redux";
import { useFormik } from "formik";
import Select from 'react-select';
import {
  Input,
  ButtonPrimary,
  Alert,
  Textarea,
  Label,
  Editor,
  NcModal,
} from "components";
import { submitPostSchema as validationSchema } from "utils/validation";
import {
  blogDetails,
  updateBlog,
  getAllTags,
  getAllCategory,
  selectBlogState,
} from "app/blogs/blogs";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { BlogRequestType, BlogResponseType } from "types/Blog";
import { AccessPlatform } from "enum";
import UploadImage from "components/UploadImage/Index";
import Checkbox from "components/Checkbox/Checkbox";

const DashboardEditPost: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { category, tags } = useAppSelector(selectBlogState, shallowEqual);
  const [data, setData] = useState<BlogResponseType | null>(null);
  const [error, setError] = useState<string>("");
  const [file, setFile] = useState<File | string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleImageChange = (event: any) => {
    setFile(event?.target?.files?.[0] || "");
  };

  const initialValues: BlogRequestType = {
    id: data?.id || "",
    accessPlatform: AccessPlatform.INSIGHTS,
    canonicalUrl: data?.canonicalUrl || "",
    faq: data?.faq || "",
    title: data?.title || "",
    altTag: data?.altTag || "",
    alias: data?.alias || false,
    aliasUrl: data?.aliasUrl || "",
    blogData: data?.blogData || "",
    tags: data?.tags?.map(i => i.id) || [],
    blogMetaTag: data?.blogMetaTag || "",
    metaKeyword: data?.metaKeyword || "",
    category: data?.category?.id || "",
    schemaMarkUp: data?.schemaMarkUp || "",
  };

  const formikForm = useFormik<BlogRequestType>({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values: BlogRequestType) => {
      setLoader(true);
      const formData = new FormData();
      formData.append("updateBlogRequest", JSON.stringify(values))
      formData.append("image", file);
      updateBlog(formData)
        .then(() => {
          setSuccess(true);
          setLoader(false);
        })
        .catch((err) => {
          setError(err?.message || "Something wrong, Please try again.");
          setLoader(false);
        })
    }
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setValues, setFieldValue } = formikForm;
  const EditorMemo = useMemo(() => (
    <Editor
      width="100"
      content={data?.blogData}
      onUpdate={(value, length) => setFieldValue("blogData", length ? value : "")}
    />
  ), [data]);

  useEffect(() => {
    if (id) {
      dispatch(blogDetails(id))
        .unwrap()
        .then((res) => setData(res))
        .catch((err) => (
          setError(err?.message || "Something wrong, Please try again.")
        ))
    }
    dispatch(getAllCategory());
    dispatch(getAllTags());
  }, []);

  return (
    <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
      <NcModal
        isOpenProp={success}
        modalTitle="Congratulations"
        contentExtraClass="max-w-screen-sm"
        renderTrigger={() => null}
        onCloseModal={() => {
          setSuccess(false);
          history.push("/dashboard/posts");
        }}
        renderContent={() => ("Your post is under review and will be approved by our editor shortly")}
      />
      {error && <Alert type="error" children={error} onClose={() => setError("")} />}
      <div className="grid md:grid-cols-2 gap-6">
        <Input
          form={formikForm}
          text="Post Title *"
          className="mt-1"
          name="title"
        />
        <label className="block md:col-span-2">
          <Label>Blog Meta Keyword *</Label>
          <Textarea
            rows={3}
            className="mt-1"
            name="metaKeyword"
            value={values.metaKeyword}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched?.metaKeyword && errors?.metaKeyword
            ? <p className="text-red-400 text-sm my-1">{errors.metaKeyword}</p>
            : null}
        </label>
        <label className="block md:col-span-2">
          <Label>Alt Tag *</Label>
          <Input
            type="text"  
            className="mt-1"
            name="altTag"
            value={values.altTag}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.altTag && errors.altTag
            ? <p className="text-red-400 text-sm my-1">{errors.altTag}</p>
            : null}
        </label>
        <label className="block md:col-span-2">
          <Checkbox
            label="Url Slug Enable"
            checked={values.alias}
            onChange={handleChange}
            name="alias"
          />
        </label>
        <label className="block md:col-span-2">
          <Label>Custom Url </Label>
          <Input
            type="text"  
            className="mt-1"
            name="aliasUrl"
            placeholder="Custom-Url"
            disabled={!values.alias}
            value={values.aliasUrl}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.aliasUrl && errors.aliasUrl
            ? <p className="text-red-400 text-sm my-1">{errors.aliasUrl}</p>
            : null}
        </label>
        <label className="block md:col-span-2">
          <Label>Meta Description *</Label>
          <Textarea
            rows={4}
            className="mt-1"
            name="blogMetaTag"
            value={values.blogMetaTag}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p className="mt-1 text-sm text-neutral-500">
            Brief description for your article.
          </p>
          {touched.blogMetaTag && errors.blogMetaTag
            ? <p className="text-red-400 text-sm my-1">{errors.blogMetaTag}</p>
            : null}
        </label>
        <label className="block">
          <Label>Category*</Label>
          <Select
            options={category?.content}
            getOptionValue={(option: any) => option.id}
            getOptionLabel={(option: any) => option.name}
            value={category?.content?.find((i: any) => i?.id === values.category)}
            onChange={(opt: any) => setFieldValue('category', opt?.id, true)}
            classNames={{ control: () => ("mt-2 submitselect bg-white dark:text-white dark:border-neutral-700 dark:bg-neutral-900") }}
            classNamePrefix="react-select"
          />
           {touched.category &&
              errors.category ? (
              <p className="text-red-600 text-sm">
                {errors.category}
              </p>
            ) : null}
        </label>
        <label className="block">
          <Label>Tags*</Label>
          <Select
            isMulti
            options={tags?.content}
            getOptionValue={(option: any) => option.id}
            getOptionLabel={(option: any) => option.name}
            value={tags?.content?.filter((i: any) => values.tags?.includes(i.id))}
            onChange={(opt) => setFieldValue('tags', opt?.map((i: any) => i.id), true)}
            classNames={{ control: () => ("mt-2 submitselect bg-white dark:border-neutral-700 dark:bg-neutral-900") }}
            classNamePrefix="react-select"
          />
           {touched.tags &&
              errors.tags ? (
              <p className="text-red-600 text-sm">
                {errors.tags}
              </p>
            ) : null}
        </label>
        <UploadImage
          title="Banner Image*"
          image={data?.bannerWebpImageUrl || ""}
          handleChange={handleImageChange}
        />
        <label className="block md:col-span-2">
          <Label>Schema Markup</Label>
          <Textarea
            className="mt-1"
            value={values.schemaMarkUp || ''}
            rows={3} name="schemaMarkUp"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </label>
        <label className="block md:col-span-2">
          <Label>FAQ Schema</Label>
          <Textarea
            rows={2}
            name="faq"
            className="mt-1"
            value={values.faq}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </label>
        <label className="block md:col-span-2">
          <Label>Canonical Url</Label>
          <Input
            name="canonicalUrl"
            className="mt-1"
            value={values.canonicalUrl}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </label>
        <div className={`block md:col-span-2 ${touched.blogData && errors.blogData ? "error" : ""}`}>
          <Label> Post Content</Label>
          {data && EditorMemo}
          {touched.blogData && errors.blogData
            ? <p className="text-red-400 text-sm my-1">{errors.blogData}</p>
            : null}
        </div>
        <ButtonPrimary
          className="md:col-span-2"
          onClick={handleSubmit}
          loading={loader}
          disabled={loader}
        >
          Update post
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default DashboardEditPost;
