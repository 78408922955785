import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios from "axios";
import {
  AuthState,
  ChangePassword,
  forgotPasswordPayload,
  LoginPayload,
  SignupPayload,
  SocialPayload,
  SubscribePayload, UpdateUserPassword,
  UserState,
} from "types/Auth";

//Social Login
export const socialLogin = createAsyncThunk(
  'user/socialLogin',
  async (payload : SocialPayload) => {
    const response = await axios.post(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/auth/social/token`, payload)
    return response.data
  }
);

//Social Login
export const fetchGoogleData = createAsyncThunk(
  'user/fetchGoogleData',
  async (code: string) => {
    const rootURl = 'https://oauth2.googleapis.com/token';
  
    const options = {
      redirect_uri: import.meta.env.VITE_GREDIRECT_URL,
      client_id: import.meta.env.VITE_GCLIENT_ID,
      grant_type: 'authorization_code',
      code,
    };
    const qs = new URLSearchParams(options);
    const response = await axios.post(rootURl, qs, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${btoa(`${import.meta.env.VITE_GCLIENT_ID}:${import.meta.env.VITE_GCLIENT_SECRET}`)}`
      },
    })
    return response.data
  }
);

//user SignUp
export const signUpAction = async (payload: SignupPayload) => {
    const response = await axios.post(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/auth/register`, payload);
    return response.data
  }

//email verify after signup
export const emailVerify = async (id: string) => {
    const response = await axios.get(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/auth/emailVerify?id=${id}`);
    return response.data
  }

// Email unsubscribe
export const emailUnsubscribe = async (email: string) => {
  const response = await axios.put(`${import.meta.env.VITE_BASE_API_URL}/public/newsletter/unsubscribe/${email}`);
  return response.data
}

//user Login
export const loginAction = createAsyncThunk(
    'user/login',
    async (payload: LoginPayload) => {
      const response = await axios.post(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/auth/login`,
       payload
       );
      return response.data
    }
);

export const forgotPassword = async (email: string) => {
    const response = await axios.post(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/auth/forgot/password/otp/${email}`)
       return response.data
  }

export const forgotNewPassword = createAsyncThunk(
  'user/newpassword',
  async (payload: forgotPasswordPayload) => {
    const response = await axios.put(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/auth/forgot/password`, payload)
        return response.data
  }
);

//user change password
export const changeUserPassword = createAsyncThunk(
  'user/changepassword',
  async (payload: ChangePassword) => {
  const response = await axios.put(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/user/changePassword`,
  payload,
  )
   return response.data
  }
);

//user update profile
export const updateProfile = createAsyncThunk(
  'user/update-profile',
  async (payload: FormData ) => {
      const response = await axios.put(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/user/update`,
      payload,
      )
      return response.data
  }
);
//update user password
export const updateUserPassword = async (payload: UpdateUserPassword): Promise<UpdateUserPassword> => {
        const response = await axios.post<UpdateUserPassword>(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/user/updateFirstTimeUser`, payload)
        if(response.status < 400) {
             return response.data
         }
        return Promise.reject(response);
    }


export const subscribe = createAsyncThunk(
  'user/subscribe',
  async (payload: SubscribePayload, { getState } : any) => {
    const response = await axios.post(`${import.meta.env.VITE_BASE_API_URL}/public/newsletter/subscribe`,payload)
    return response.data
  }
);

export const logOut = createAsyncThunk(
  'user/LOGOUT',
  async () => { 
    const response = await axios.post(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/auth/logout`);
    return response.data
   }
);

const initialState: AuthState = {
  user: null,
  token: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    setToken: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        token: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(loginAction.fulfilled, (state, action) => {
      // Add user to the state array
      state.token = action.payload?.accessToken || ""
      state.user = action.payload?.userResponse
    })
    .addCase(socialLogin.fulfilled, (state, action) => {
      // Add user to the state array
      state.token = action.payload?.accessToken || ""
      state.user = action.payload?.userResponse
    })
    .addCase(updateProfile.fulfilled, (state, action) => {
      state.user = action.payload
    })
    .addCase(logOut.fulfilled, (state, action) => {
      // Add user to the state array
      return initialState
    })
  },
});

export const { setUser, setToken } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;