import React from "react";
import { useAppSelector } from "app/hooks";
import { selectAuthState } from "app/auth/authSlice";
import {
  ButtonPrimary,
  Logo,
  MenuBar,
  Navigation,
  ErrorBoundary,
  AvatarDropdown,
} from "components";
import DarkModeContainer from "containers/DarkModeContainer/DarkModeContainer";
import { NAVIGATION_SHORT_DEMO } from "data/navigation";
import NotifyDropdown from "./NotifyDropdown";

const MainNav2Logged: React.FC<{}> = () => {
  const { token } = useAppSelector(selectAuthState);
  return (
    <div className={`nc-MainNav nc-MainNav2 relative z-10`}>
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo height="80" width= "250" />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-2">
            <Navigation navigations={NAVIGATION_SHORT_DEMO} />
            {token && (
              <ButtonPrimary href={"/dashboard/submit-post"} sizeClass="px-4 py-2 sm:px-5">
               Create Post
              </ButtonPrimary>
            )}
            <div className="hidden sm:block h-6 border-l border-neutral-300 dark:border-neutral-6000"></div>
            <div className="flex">
              <DarkModeContainer />
            </div>
            <div></div>
            {!token && (
              <ButtonPrimary href={"/login"} sizeClass="px-4 py-2 sm:px-5">
                Log in
              </ButtonPrimary>
            )}
            {token && <><NotifyDropdown /> <AvatarDropdown /></> }
          </div>
          <div className="flex items-center space-x-3 xl:hidden">
            {token && (<><NotifyDropdown /> <AvatarDropdown /></> )}
            <ErrorBoundary>
              <MenuBar />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2Logged;
