import LayoutPage from "components/LayoutPage/LayoutPage";
import React from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
const loginSocials = [
    {
      id:3,
      href: " ",
      icon: googleSvg,
    },
    {
      id:2,
      href: "#",
      icon: twitterSvg,
    },
    {
      id:1,
      href: "#",
      icon: facebookSvg,
    },
  
  
];

const VerfiyLoginPage = () => { 
  const {email} =useParams<any>()
  return (
    <div className={`nc-PageLogin `} data-nc-id="PageLogin">
      <Helmet>
        <title>Check Your Mail</title>
      </Helmet>
      <LayoutPage
         subHeading="Verify Your Mail"
         headingEmoji="🔑"
         heading="DZ-Insights"
      
      >
        <div className="max-w-md mx-auto space-y-6">
          
          {/* OR */}
          {/* Content */}
           <div className="text-center">
            <span className="dark:bg-neutral-800 my-4">
                We Have sent a verification email to {email}  Please Confirm you email to access.
            </span>
            <span>
                <h5 className="m-4">Open Your Mailbox</h5>
            </span>
            <span>
                <div className="flex gap-3 my-4">
                {loginSocials.map((item, index) => (
                <a
                    key={item.id}
                    href={item.href}
                    className="flex w-full rounded-lg dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                >
                    <img
                    // className="flex-shrink-0"
                    src={item.icon}
                    width="40%"
                    />
                </a>
                ))}
                </div>
            </span>
            <span>
                <h6 className="m-4 text-neutral-800 dark:text-neutral-200">Didn't Receive Our email ?</h6>
            </span> 
            <span className="text-neutral-800 dark:text-neutral-200">
                Check your spam folder contact support. We will set up your account manually.
            </span>
           </div>
        </div>
      </LayoutPage>
    </div>
  );
};

export default VerfiyLoginPage;