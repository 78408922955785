import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import Select from 'react-select';
import {
  Input,
  ButtonPrimary,
  Alert,
  Textarea,
  Label,
  Editor,
  NcModal,
} from "components";
import { submitPostSchema as validationSchema } from "utils/validation";
import { addBlog, createBlog, getAllCategory, getAllTags, resetState, selectBlogState } from "app/blogs/blogs";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { BlogRequestType, PreviewBlog } from "types/Blog";
import { AccessPlatform } from "enum";
import UploadImage from "components/UploadImage/Index";
import Checkbox from "components/Checkbox/Checkbox";

const DashboardSubmitPost: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { previewBlogData } = useAppSelector(selectBlogState)
  const { category, tags } = useAppSelector(selectBlogState);
  const [error, setError] = useState<string>("");
  const [file, setFile] = useState<File | string >("");
  const [fileError, setFileError] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false); 
  const [success, setSuccess] = useState<boolean>(false);
  const showImage = previewBlogData?.image ? URL.createObjectURL(previewBlogData.image as Blob) : ""

  const formikForm = useFormik<BlogRequestType>({
    enableReinitialize: true,
    initialValues: previewBlogData?.content ? previewBlogData.content :  {
      accessPlatform: AccessPlatform.INSIGHTS,
      alias: false,
      aliasUrl: "",
      altTag: "",
      blogMetaTag: "",
      blogData: '',
      category: "",
      faq: "",
      canonicalUrl: "",
      metaKeyword: "",
      schemaMarkUp: "",
      title: "",
      tags: [],
    },
    validationSchema,
    onSubmit: async (values: BlogRequestType) => {
      if (!file){
        setFileError(true);
        return false;
      };
      setLoader(true);
      const formData = new FormData();
      formData.append("blogRequest", JSON.stringify(values))
      formData.append("image", file);
      createBlog(formData)
        .then(() => {
          dispatch(resetState())
          setSuccess(true);
          setLoader(false);
        })
        .catch((err) => {
          setError(err?.message || "Something wrong, Please try again.");
          setLoader(false);
        })
    }
  });
  const handlePreview = (values: BlogRequestType) => {
    setLoader(true);
    const tagMeta :any = tags?.content?.filter(item => values?.tags?.includes(item.id));
    const categoryMeta :any = category?.content?.filter(item => item?.id == values?.category)
    const data =  {
      content : values,
      image: file,
      tagList:tagMeta,
      categoryList: categoryMeta
    }  
    if(values.title && values.altTag && values.metaKeyword && values.blogMetaTag && values.tags && values.category && values.blogData){    
      dispatch(addBlog(data))
      history.push(`/blog-preview`)
    }else{
      setLoader(false)
      setError("Fill Required Fields")
    }
  } 
 
  const handleImageChange = (event: File) => {
    setFile(event || "");
  };

  const EditorMem = useMemo(() => (
    <Editor
      width="100"
      content={previewBlogData?.content ? previewBlogData.content.blogData:""}
      onUpdate={(value, length) => setFieldValue("blogData", length ? value : "")}
    />
  ), []);

  useEffect(() => {
    dispatch(getAllTags());
    dispatch(getAllCategory());
    setFile(previewBlogData ? previewBlogData.image : "")
  }, []);

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formikForm;
  return (
    <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
      <NcModal
        isOpenProp={success}
        modalTitle="Congratulations"
        contentExtraClass="max-w-screen-sm"
        renderTrigger={() => null}
        onCloseModal={() => {
          setSuccess(false);
          history.push("/dashboard/posts");
        }}
        renderContent={() => ("Your post is under review and will be approved by our editor shortly")}
      />
      {error && <Alert type="error" children={error} onClose={() => setError("")} />}
      <div className="grid md:grid-cols-2 gap-6">
        <Input
          form={formikForm}
          text="Post Title *"
          className="mt-1"
          name="title"
        />
        <label className="block md:col-span-2">
          <Label>Blog Meta Keyword *</Label>
          <Textarea
            rows={3}
            className="mt-1"
            name="metaKeyword"
            value={values.metaKeyword}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched?.metaKeyword && errors?.metaKeyword
            ? <p className="text-red-400 text-sm my-1">{errors.metaKeyword}</p>
            : null}
        </label>
        <label className="block md:col-span-2">
          <Label>Alt Tag *</Label>
          <Input
            className="mt-1"
            name="altTag"
            value={values.altTag}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.altTag && errors.altTag
            ? <p className="text-red-400 text-sm my-1">{errors.altTag}</p>
            : null}
        </label>
        <label className="block md:col-span-2">
          <Checkbox
            label="Url Slug Enable"
            checked={values.alias}
            onChange={handleChange}
            name="alias"
          />
        </label>
        <label className="block md:col-span-2">
          <Label>Custom Url </Label>
          <Input
            className="mt-1"
            name="aliasUrl"
            placeholder="Custom-Url"
            disabled={!values.alias}
            value={values.aliasUrl}
            onChange={handleChange}
            onBlur={handleBlur}
          />
           { values.alias  ? 
           <p className="text-red-400 text-sm my-1">{errors.aliasUrl}</p>
            : null }
        </label>
        <label className="block md:col-span-2">
          <Label>Meta Description *</Label>
          <Textarea
            rows={4}
            className="mt-1"
            name="blogMetaTag"
            value={values.blogMetaTag}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.blogMetaTag && errors.blogMetaTag
            ? <p className="text-red-400 text-sm my-1">{errors.blogMetaTag}</p>
            : null}
        </label>
        <label className="block">
          <Label>Category*</Label>
          <Select
            options={category?.content}
            getOptionValue={(option: any) => option.id}
            getOptionLabel={(option: any) => option.name}
            value={category?.content?.find((i: any) => i?.id === values.category)}
            onChange={(opt: any) => setFieldValue('category', opt?.id, true)}
            classNames={{ control: () => ("mt-2 submitselect bg-white dark:text-white dark:border-neutral-700 dark:bg-neutral-900") }}
            classNamePrefix="react-select"
          />
          {touched.category &&
            errors.category ? (
            <p className="text-red-600 text-sm">
              {errors.category}
            </p>
          ) : null}
        </label>
        <label className="block">
          <Label>Tags*</Label>
          <Select
            isMulti
            options={tags?.content}
            getOptionValue={(option: any) => option.id}
            getOptionLabel={(option: any) => option.name}
            value={tags?.content?.filter((i: any) => values.tags?.includes(i.id))}
            onChange={(opt) => setFieldValue('tags', opt?.map((i: any) => i.id), true)}
            classNames={{ control: () => ("mt-2 submitselect bg-white dark:border-neutral-700 dark:bg-neutral-900") }}
            classNamePrefix="react-select"
          />
          {touched.tags &&
            errors.tags ? (
            <p className="text-red-600 text-sm">
              {errors.tags}
            </p>
          ) : null}
        </label>
        <UploadImage
          error={fileError}
          title="Banner Image*"
          image={ file ? file : showImage}
          handleChange={handleImageChange}
        />
        <label className="block md:col-span-2">
          <Label>Schema Markup</Label>
          <Textarea
            className="mt-1"
            value={values.schemaMarkUp || ''}
            rows={3} name="schemaMarkUp"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </label>
        <label className="block md:col-span-2">
          <Label>FAQ Schema</Label>
          <Textarea
            rows={2}
            name="faq"
            className="mt-1"
            value={values.faq}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </label>
        <label className="block md:col-span-2">
          <Label>Canonical Url</Label>
          <Input
            name="canonicalUrl"
            className="mt-1"
            value={values.canonicalUrl}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </label>       
        <div className={`block md:col-span-2 ${touched.blogData && errors.blogData ? "error" : ""}`}>
            <Label> Post Content*</Label>
            {EditorMem}
            {touched.blogData && errors.blogData
              ? <p className="text-red-600 text-sm my-1">{errors.blogData}</p>
              : null}
          </div>
        <div className=" flex ">
          <ButtonPrimary
            type="button"
            className="md:col-span-2"
            onClick={() => handlePreview(values)}
            disabled={loader}
          >
            Preview
          </ButtonPrimary>
          <ButtonPrimary
            type="button"
            className="md:col-span-2 mx-5"
            onClick={handleSubmit}
            loading={loader}
            disabled={loader}
          >
            Submit post
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default DashboardSubmitPost;
