import React, {FC, useEffect, useState} from "react";
import WidgetAuthors from "components/WidgetAuthors/WidgetAuthors";
import WidgetCategories from "components/WidgetCategories/WidgetCategories";
import WidgetPosts from "components/WidgetPosts/WidgetPosts";
import WidgetTags from "components/WidgetTags/WidgetTags";
import { DEMO_AUTHORS } from "data/authors";
import { DEMO_POSTS } from "data/posts";
import { DEMO_CATEGORIES, DEMO_TAGS } from "data/taxonomies";
import { PostAuthorType, PostDataType, TaxonomyType } from "data/types";
import {BlogResponseType, Pageable, TagsResponse} from "../../types/Blog";
import {getAllTags, getTop10Authors, searchBlogs, selectBlogState} from "../../app/blogs/blogs";
import {AccessPlatform} from "../../enum";
import {PostDataConverter} from "../../app/modals/PostDataConverter";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { TagsConverter } from "app/modals/TagConverter";
import { AuthorConverter } from "app/modals/AuthorConverter";

export interface SidebarProps {
  className?: string;
}

const widgetPosts: PostDataType[] = DEMO_POSTS.filter((_, i) => i > 2 && i < 7);
const tags = DEMO_TAGS.filter((_, i) => i > 5);
const categories = DEMO_CATEGORIES.filter((_, i) => i > 7 && i < 13);
const authors = DEMO_AUTHORS.filter((_, i) => i < 5);

export const Sidebar: FC<SidebarProps> = ({ className = "space-y-6 " }) => {
  const { trendingTopic } = useAppSelector(selectBlogState)
  const dispatch = useAppDispatch()
  const [tags, setTags] = useState<TaxonomyType[]>([])
    const [latestArticles, setLatestArticles] = useState<PostDataType[]>([]);
  const [authors, setAuthors] = useState<PostAuthorType[]>([]);
    const [error, setError] = useState<string>('')
    const [page, setPage] = useState<Pageable>({
    pageNo: 0,
    pageSize: 10,
    });
    useEffect(() => {
      getTop10Authors(page)
      .then((res) => {
        const _post = res.content.map((val)=>{
          return new AuthorConverter(val)
        })
        setAuthors(_post);
      })
      .catch((err) => setError(err?.message || "Something wrong, Please try again."));   
    const params = {
      pageNo: `${page.pageNo}`,
      pageSize: `${page.pageSize}`,
      orders: `publishedDate|desc||totalViewer|desc`,
      filterAnd : `status|eq|APPROVED&accessPlatform|in|${AccessPlatform.INSIGHTS}`
    }
    const urlPath= new URLSearchParams(params).toString();
    searchBlogs(urlPath)
        .then((res)=>{
          const _post = res.content.map((val:BlogResponseType)=>{
            return new PostDataConverter(val)
          })
            setLatestArticles(_post.filter((_, i) => i >= 0 && i < 7));
      }).catch((err) => setError(err.message || "Something Went Wrong"));
      dispatch(getAllTags())
    .unwrap()
    .then((res) => {
    if(res) {
    const data = res?.content?.map((t: TagsResponse) => {
    return new TagsConverter(t)
    })
    return setTags(data)
    }
    }).catch((err) => setError(err?.message || "Something wrong, Please try again."));
  }, []);
  return (
    <div className={`nc-SingleSidebar ${className}`}>
      <WidgetTags tags={tags.filter((_, i) => i >= 0 && i < 15)} />
      <WidgetCategories categories={trendingTopic.filter((_, i) => i >= 0 && i < 7)} />
      <WidgetAuthors authors={authors} />
      <WidgetPosts posts={latestArticles.filter((_, i) => i >= 0 && i < 7)} />
    </div>
  );
};
