import { CommentType } from "components/CommentCard/CommentCard";
import { SinglePageType } from "containers/PageSingle/PageSingleTemp3Sidebar";
import { TaxonomyType, PostAuthorType } from "data/types";
import { BlogResponseType } from "types/Blog";
import { Author } from "./Author";
import { Taxonomy } from './Taxonomy';

export class BlogConverter implements SinglePageType {
    tags: TaxonomyType[];
    content: React.ReactNode;
    comments: CommentType[];
    id: string | number;
    author: PostAuthorType;
    date: string;
    href: string;
    categories: TaxonomyType[];
    title: string;
    featuredImage: string;
    desc?: string | undefined;
    like: { count: number; isLiked: boolean; };
    bookmark: { count: number; isBookmarked: boolean; };
    commentCount: number;
    viewdCount: number;
    readingTime: number;
    postType: "standard" | "video" | "gallery" | "audio";
    altTag?: string;
    metakeywords?: string;
    schemamarkup?:string;
    faq?: string;


    constructor(data: BlogResponseType) {
        this.id = data.id;
        this.tags = [...data.tags?.map(i => {
          return new Taxonomy(i.id, i.name, "", 'tag')
        })];
        this.content = data.blogData;
        this.comments = [];
        this.author = new Author(data.createdBy); 
        this.date = data.publishedDate;
        this.href = "/blog/" + data.uniqueUrl;
        this.desc = data.blogMetaTag;
        this.categories = [new Taxonomy(data?.category?.id || "", data?.category?.name || "", data?.category?.slug || "", "category")];
        this.title = data.title;
        this.featuredImage = data?.bannerWebpImageUrl || "";
        this.like = { count : data.likes, isLiked: false }
        this.bookmark =  { count: 0, isBookmarked: false };
        this.commentCount = data.comments;
        this.viewdCount = 10;
        this.readingTime = 10;
        this.postType = "standard";
        this.altTag = data.altTag;
        this.metakeywords = data.metaKeyword;
        this.schemamarkup = data.schemaMarkUp || "";
        this.faq = data.faq
    }
}