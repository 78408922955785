import React, {FC} from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export interface PageLoaderProps {
    className?: string;
  }

const Loader: FC<PageLoaderProps> = ({ className = "" }) => {
  return (
    <Box sx={{ display: 'flex' }}>
        <CircularProgress />
    </Box>
  );
}
export default Loader;