import React, { ComponentType } from "react";
import { Helmet } from "react-helmet";
import { NavLink, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import LayoutPage from "components/LayoutPage/LayoutPage";
import DashboardUpdatePassword from "./DashboardUpdatePassword";
import DashboardEditProfile from "./DashboardEditProfile";
import DashboardSubmitPost from "./DashboardSubmitPost";
import DashboardEditPost from "./DashboardEditPost";
import DashboardPosts from "./DashboardPosts";
import DashboardRoot from "./DashboardRoot";

export interface PageDashboardProps {
  className?: string;
}

interface DashboardLocationState {
  "/submit-post"?: {};
  "/posts"?: {};
  "/profile"?: {};
  "/posts/edit/:id"?:{};
  "/reset-password"?:{}
}

interface DashboardPage {
  sPath?: keyof DashboardLocationState;
  exact?: boolean;
  component: ComponentType<Object>;
  emoij?: string;
  pageName?: string;
}

const subPages: DashboardPage[] = [
  {
    sPath: "/submit-post",
    exact: true,   
    component: DashboardSubmitPost,
    emoij: "✍",
    pageName: "Submit post",
  },
  {
    sPath: "/posts/edit/:id",
    component: DashboardEditPost,
  }, 
  {
    sPath: "/posts",
    component: DashboardPosts,
    emoij: "📕",
    pageName: "My Posts",
  },
  {
    sPath: "/profile",
    component: DashboardEditProfile,
    emoij: "🛠",
    pageName: "My Profile",
  },   
  {
    sPath: "/reset-password",
    component: DashboardUpdatePassword,
    emoij: "🔑",
    pageName: "Update Password",
  },
];

const PageDashboard: React.FC<PageDashboardProps> = ({ className = "" }) => {
  let { path, url } = useRouteMatch();

  return (
    <div className={`nc-PageDashboard ${className}`} data-nc-id="PageDashboard">
      <Helmet>
        <title>DZ Insights - Discover The World </title>
      </Helmet>
      <LayoutPage
        subHeading="Manage your Posts and Profile all at one place"
        // headingEmoji="⚙"
        heading="DASHBOARD"
      >
        <div className="grid grid-cols-4">
          {/* SIDEBAR */}

          <div className="flex-shrink-0 max-w-xl xl:w-80 xl:pr-8">
            <ul className="text-base space-y-1 text-neutral-6000 dark:text-neutral-400">
              {subPages.filter(path => path?.pageName).map(({ sPath, pageName, emoij }) => {
                return (
                  <li key={sPath}>
                    <NavLink
                      to={`${url}${sPath}`}
                      className="flex px-6 py-2.5 font-medium rounded-lg hover:text-neutral-800 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-100"
                      activeClassName="bg-neutral-100 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-100"
                    >
                      <span className="w-8 mr-1">{emoij}</span>
                      {pageName}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="border border-neutral-100 dark:border-neutral-800 md:hidden"></div>
          <div className="flex-grow col-span-3 ml-5">
            <Switch>
              {subPages.map(({ component, sPath, exact }) => {
                return (
                  <Route
                    key={sPath}
                    exact={exact}
                    component={component}
                    path={`${path}${sPath}`}
                  />
                );
              })}
              <Redirect to={path + "/submit-post"} />
            </Switch>
          </div>
        </div>
      </LayoutPage>
    </div>
  );
};

export default PageDashboard;
