import React from "react";
import ButtonClose from "components/ButtonClose/ButtonClose";

export interface AlertProps {
  children: React.ReactNode;
  containerClassName?: string;
  type?: "default" | "warning" | "info" | "success" | "error";
  onClose?: () => void
}

const Alert: React.FC<AlertProps> = ({
  children = "Alert Text",
  containerClassName = "",
  type = "default",
  onClose
}) => {
  let classes = containerClassName;
  switch (type) {
    case "default":
      classes += " text-black bg-neutral-900";
      break;
    case "info":
      classes += " bg-status-infoBg text-status-info";
      break;
    case "success":
      classes += " bg-status-successBg text-status-success";
      break;
    case "error":
      classes += " bg-status-errorBg text-status-error bg-red-600 text-white";
      break;
    case "warning":
      classes += " bg-status-warningBg text-status-warning";
      break;
    default:
      break;
  }

  return (
    <div
      className={`ttnc-alert fixed flex items-center text-paragraph-base px-6 pt-4 pb-3 rounded-lg z-10 top-32 right-0 w-fit ${classes}`}
    >
      <i className="pe-7s-info text-2xl mr-2"></i>
      {children}
      <ButtonClose onClick={onClose}/>
    </div>
  );
};

export default Alert;

