import React, { FC, ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PostDataType, TaxonomyType } from "data/types";
import SingleContent from "./SingleContent";
import { CommentType } from "components/CommentCard/CommentCard";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Sidebar } from "./Sidebar";
import SingleHeader from "./SingleHeader";
import {  createBlog, resetState,  selectBlogState } from "app/blogs/blogs";
import SubHeader from "components/Header/SubHeader";
import Avatar from "data/avatars/7309681.jpg"
import { Alert, ButtonPrimary, NcImage, NcModal } from "components";
import { selectAuthState } from "app/auth/authSlice";

export interface PreviewBlog {
  className?: string;
}

export interface SinglePageType extends PostDataType {
  tags: TaxonomyType[];
  content: string | ReactNode;
  comments: CommentType[];
}

const PreviewBlog: FC<PreviewBlog> = ({
  className = "",
}) => {
  const dispatch = useAppDispatch();
  const [pageData, setPageData] = useState<SinglePageType>();
  const [loader, setLoader] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const  { previewBlogData } = useAppSelector(selectBlogState)
  const { user } = useAppSelector(selectAuthState)
  const [error, setError] = useState<string>('')
  const history = useHistory()
  let currentUrl = history.location.pathname

  // UPDATE CURRENTPAGE DATA IN PAGEREDUCERS
  useEffect(() => {     
    if(previewBlogData?.image !== ''){
      setPageData({   
        title: previewBlogData?.content.title,    
        tags : previewBlogData?.tagList,
        content : previewBlogData?.content.blogData,
        categories : previewBlogData?.categoryList,
        featuredImage : URL.createObjectURL(previewBlogData?.image as Blob),
        comments : [],
        id : 1,
        date : new Date().toDateString(),
        href : "",
        desc : previewBlogData?.content.blogMetaTag,
        like : { count: 0,isLiked: false },
        bookmark : { count: 0, isBookmarked: false },
        commentCount : 0,
        viewdCount : 0,
        readingTime : "0",
        postType : "standard",
        author : { 
          id: user?.id || "", 
          firstName: user?.firstName || "",
          lastName: user?.lastName || "", 
          displayName: user ? user.firstName  : "", 
          avatar : user?.avatar.thumbnail ? user?.avatar.thumbnail :Avatar, 
          bgImage: "", 
          email: user?.email || "", 
          count: 0 , 
          desc: "", 
          jobName: "", 
          href: ""
        },
        altTag : previewBlogData?.content.altTag
      })
    }else{
      history.push('/errorpage')
    }   
  },[currentUrl]);

  const handleSubmit = () => {
    setLoader(true)
    const formData = new FormData();
      formData.append("blogRequest", JSON.stringify(previewBlogData.content))
      formData.append("image", previewBlogData.image);
      createBlog(formData)
        .then(() => {
          setSuccess(true);
          setLoader(false);
          dispatch(resetState())
        })
        .catch((err) => {
          setError(err?.message || "Something wrong, Please try again.");
          setLoader(false);
        })      
  }

  return (
    <>   
    <NcModal
        isOpenProp={success}
        modalTitle="Congratulations"
        contentExtraClass="max-w-screen-sm"
        renderTrigger={() => null}
        onCloseModal={() => {
          setSuccess(false);
          history.push("/dashboard/posts");
        }}
        renderContent={() => ("Your post is under review and will be approved by our editor shortly")}
      />   
      {pageData && <SubHeader data={pageData} />}
      <div
        className={`nc-PageSingleTemp3Sidebar ${className}`}
        data-nc-id="PageSingleTemp3Sidebar"
      >
        <header className="relative pt-16 z-10 md:py-20 lg:py-28 bg-neutral-900 dark:bg-black">
          {/* SINGLE HEADER */}
          <div className="dark container relative z-10">
            <div className="max-w-screen-md">
              {pageData && <SingleHeader
                hiddenDesc
                metaActionStyle="style2"
                pageData={pageData }
              />}
            </div>
          </div>
          {error && <Alert type="error" children={error} onClose={() => setError("")} />}
          {/* FEATURED IMAGE */}
          <div className="mt-8 md:mt-0 md:absolute md:top-0 md:right-0 md:bottom-0 md:w-1/2 lg:w-2/5 2xl:w-1/3">
            <div className="hidden md:block absolute top-0 left-0 bottom-0 w-1/5 from-neutral-900 dark:from-black bg-gradient-to-r"></div>
            <NcImage
              className="block w-full h-full object-cover"
              src={pageData?.featuredImage}
              alt={pageData?.altTag}
            />
          </div>
        </header>

        {/* SINGLE MAIN CONTENT */}
        <div className="container flex flex-col my-10 lg:flex-row ">
          <div className="editor w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
            { pageData && <SingleContent data={pageData} /> }
          </div>
        </div>

        {/* RELATED POSTS */}     
      </div>
      <div className="container flex flex-col my-10 lg:flex-row ">
      <ButtonPrimary
          type="button"
          className="md:col-span-2 w-6/12 "
          onClick={() => history.push('/dashboard/submit-post')}
          disabled={loader}
        >
          Back To Post Edit
        </ButtonPrimary>
        <ButtonPrimary
          type="button"
          className="md:col-span-2 w-6/12 mx-5"
          onClick={handleSubmit}
          disabled={loader}
          loading={loader}
        >
          Submit post
        </ButtonPrimary>
      </div>     
    </>
  );
};

export default PreviewBlog;
