import { TaxonomyType } from "data/types";
import { TagsResponse } from "types/Blog";
import { getRandomColor } from "utils";
export class TagsConverter implements TaxonomyType {
  id: string | number;
  name: string;
  href: string;
  count?: number;
  thumbnail?: string;
  color?:  string;
  taxonomy: "category" | "tag";

    constructor(data: TagsResponse ) {
      this.id = data.id
      this.name = data.name
      this.href = `/discover?tag=${data.id}`
      this.thumbnail = ""
      this.taxonomy = "tag"
      this.color = getRandomColor()
      this.count = data.totalCount      
    }
}