import React from "react";
interface LogoSvgProps {
  width: string;
  height: string;
}
const LogoSvg: React.FC<LogoSvgProps> = ({width,height}) => {
  const imgUrl = new URL('/public/logo.png', import.meta.url).href

return (<img src={imgUrl} alt="logo" height={height} width={width}/>);
};

export default LogoSvg;
