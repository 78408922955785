import { FC } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LayoutPage from "components/LayoutPage/LayoutPage";

const Guidelines: FC = () => {

  return (
    <div className="">
       <Helmet>
        <title>Guidelines- DZ Insights</title>
        <meta name="title" content=" DZ Insights Content Guidelines" />
        <meta name="description" content="Want to get your blog posted on DZ Insights? Check out the guidelines to write a blog that meets our requirements and get it published without a hitch." />
      </Helmet>
      <LayoutPage
        subHeading=""
        headingEmoji="📝"
        heading="Guidelines"
      >
       <div>
     <div className="">
      <h2 className="font-semibold flex items-center justify-center">Blog Writing Guidelines</h2>
      <br/>
      <p >
      Welcome to our blogging website! We're excited to provide a platform for you to share your knowledge, experiences, and insights with our diverse community of readers. To ensure a seamless and enriching experience for everyone, we have put together these blog submission guidelines. By following these guidelines, you'll increase the chances of your blog being accepted and published on our website.      </p>
      <br/>
     <h2 className="font-semibold font-style: italic">1. Content Relevance and Originality:</h2>
     <br/>
      <p>
        We are committed to offering our readers valuable and unique content. Your blog should be original, well-researched, and relevant to our website's theme. Avoid submitting content that has been previously published elsewhere or contains plagiarized material.
      </p>
      <br />
      <h2 className="font-semibold font-style: italic">2. Writing Style and Tone:</h2>
      <br/>
      <p>
        Write in a clear, engaging, and informative manner. Write in a friendly and approachable tone that resonates with our readers. Blogs should be well-structured with logical flow, and any claims or data presented should be backed up with credible sources.
      </p>
      <br />
      <h2 className="font-semibold font-style: italic">3. Word Count:</h2>
      <br/>
      <p>
        We recommend aiming for a word count of 800 to 1500 words. This allows you to delve into the topic adequately while keeping the reader's attention. Longer pieces will be considered if the content justifies the extended length.
      </p>
      <br />
      <h2 className="font-semibold font-style: italic">4. Include Visuals:</h2>
      <br/>
      <p>
        Add relevant images, infographics, or videos to your blog. Visual elements enrich the reading experience and help illustrate your points. Ensure you have the necessary rights or permissions to use any visuals you include.
      </p>
      <br />
      <h2 className="font-semibold font-style: italic">5. Proper Attribution and Citations:</h2>
      <p>
        If you reference data, statistics, quotes, or information from other sources, provide proper attribution and citations. Plagiarism is strictly prohibited and may lead to the rejection of your submission.
      </p>
      <br />
      <h2 className="font-semibold font-style: italic">6. SEO Considerations:</h2>
      <br/>
      <p>
        While we appreciate well-optimized content, please prioritize writing for readers rather than search engines. Include relevant keywords naturally within the content, and refrain from keyword stuffing.
      </p>
      <br />
      <h2 className="font-semibold font-style: italic">7. Subheadings and Formatting:</h2>
      <br/>
      <p>
        Break up your blog with descriptive subheadings that guide readers through the content. Use bullet points and numbered lists when appropriate to make the text more scannable and easy to follow.
      </p>
      <br />
      <h2 className="font-semibold font-style: italic">8. Avoid Offensive or Controversial Content:</h2>
      <p>
        We encourage open discussions, but any content promoting hate speech, discrimination, or offensive material will not be accepted. Consider the perspectives of others and be respectful of them.
      </p>
      <br />
      <h2 className="font-semibold font-style: italic">9. Edit and Proofread:</h2>
      <br/>
      <p>
        Before submitting your blog, thoroughly edit and proofread it for errors. A well-polished piece increases your chances of acceptance and creates a positive impression.
      </p>
      <br />
      <h2 className="font-semibold font-style: italic">10. Author Bio and Profile Picture:</h2>
      <br/>
      <p>
        Include a short author bio (approximately 50-100 words) at the end of your blog. You may also provide a professional profile picture to accompany your bio.
      </p>
      <br />
      <h2 className="font-semibold font-style: italic">11. Exclusive Content:</h2>
      <br/>
      <p>
        We prefer content that is exclusive to our website and has not been simultaneously submitted to other platforms. If your blog is accepted, please refrain from reposting it elsewhere for a reasonable period.
      </p>
      <br />
      <h2 className="font-semibold font-style: italic">12. Submission Process:</h2>
      <br/>
      <p>
        To submit your blog, please create your account to gain the access to add a Post option or get in touch with our team through the <Link className="text-blue-600" to={"/contact"} aria-label="contact us page" >Contact-Us</Link> page. Our editorial team will review your submission and respond within 2 working days regarding its status. While we appreciate all submissions, please understand that we may not be able to publish every blog.
      </p>
      <br />
      <p>
        We are thrilled to have you as a contributor to our blogging website. By adhering to these guidelines, you help us maintain the quality and integrity of our platform while providing valuable insights to our readers. Thank you for being part of our vibrant community of bloggers!
      </p>
      <br />
       
    </div>
     </div>
   
      </LayoutPage>
    </div>
  );
};

export default Guidelines;
