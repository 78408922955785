import { getAllTags, searchBlogs, selectBlogState } from "app/blogs/blogs";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { PostDataConverter } from "app/modals/PostDataConverter";
import { TagsConverter } from "app/modals/TagConverter";
import { Alert } from "components";
import { PostAuthorType, PostDataType, TaxonomyType } from "data/types";
import { AccessPlatform } from "enum";
import { FC, useEffect, useState } from "react";
import { BlogResponseType, Pageable, TagsResponse } from "types/Blog";
import SectionLatestPosts from "./SectionLatestPosts";

export interface LatestPostWrapperProps {
  widgetPosts?: PostDataType[];
  categories?: TaxonomyType[];
  tags?:TaxonomyType[];
  authors?:PostAuthorType[];
}

const LatestPostWrapper: FC<LatestPostWrapperProps> = ({ widgetPosts, categories,authors}) => {
  const { trendingTopic } = useAppSelector(selectBlogState)
    const dispatch = useAppDispatch()
    const [latestPost, setLatestPost] =  useState<PostDataType[]>([])
    const [totalItems, setTotalItems] =  useState<number>(0)
    const [tags, setTags] = useState<TaxonomyType[]>([])
    const [error, setError] = useState<string>('')
    const [page, setPage] = useState<Pageable>({
      pageNo: 0,
      pageSize: 7,
    });
    const getData = (value: Pageable = { pageNo:0, pageSize: 7}) => {
        setPage({pageNo: value?.pageNo, pageSize: value?.pageSize })
        const params = {
          pageNo: `${ value?.pageNo }` ,
          pageSize: `${ value?.pageSize }` ,
          orders: `publishedDate|desc||totalViewer|desc`,
          filterAnd : `status|eq|APPROVED&accessPlatform|in|${AccessPlatform.INSIGHTS}`
        }
        const urlPath= new URLSearchParams(params).toString();    
        searchBlogs(urlPath)
        .then((res) => {
            setTotalItems(res.totalElements)
          const _post = res.content.map((val:BlogResponseType)=>{
            return new PostDataConverter(val)
          })
          setLatestPost(_post)
        })
        .catch((err) => setError(err?.message || "Something wrong, Please try again."));        
      }
      useEffect(() => {
        getData()
        dispatch(getAllTags())
        .unwrap()
        .then((res) => {
          if(res) {        
            const data = res?.content?.map((t: TagsResponse) => {
              return new TagsConverter(t)
            })
            return setTags(data)
          }
        })
      .catch((err) => setError(err?.message || "Something wrong, Please try again."));
      },[])
   return (
    <>
     {error && <Alert type="error" children={error} onClose={() => setError("")} />}
      <SectionLatestPosts
        widgetPosts={widgetPosts}
        categories={trendingTopic.filter((_, i) => i >= 0 && i < 7)}
        tags={tags.filter((_, i) => i >= 0 && i < 20)}
        className="pb-16 lg:pb-28"
        itemsPerPage={page.pageSize}
        totalItems={totalItems}
        posts={latestPost}
        onPageChange={getData}
        authors={authors}
      />
    </>
   )
  }
  export default LatestPostWrapper;