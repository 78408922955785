import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";
import LogoSvg from "../Logo/LogoSvg"
export interface LogoProps {
  img?: string;
  imgLight?: string;
  width: string;
  height: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  width,
  height
}) => {
  return (
    <Link aria-label="rendering page" to="/" className="ttnc-logo inline-block text-primary-6000">
      {/* THIS USE FOR MY MULTI DEMO */}
      {/* IF YOU ARE MY CLIENT. PLESE DELETE THIS CODE AND YOU YOUR IMAGE PNG BY BELLOW CODE */}
      <LogoSvg width={width} height={height}/>
    </Link>
  );
};

export default Logo;
