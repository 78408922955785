import React from "react";
import { avatarColors } from "contains/contants";
import NcImage from "components/NcImage/NcImage";

export interface AvatarProps {
  containerClassName?: string;
  sizeClass?: string;
  radius?: string;
  imgUrl?: string;
  userName?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  containerClassName = "ring-1 ring-white dark:ring-neutral-900",
  sizeClass = "h-6 w-6 text-sm",
  radius = "rounded-md",
  imgUrl = "",
  userName,
}) => {
  const url = imgUrl || "";
  const name = userName || "D";
  const _setBgColor = () => {
    const backgroundIndex = Math.floor(
      name.charCodeAt(0) % avatarColors.length
    );
    return avatarColors[backgroundIndex];
  };

  return (
    <div
      className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold shadow-inner ${radius} ${sizeClass} ${containerClassName}`}
      style={{ backgroundColor: url !== "N/A" ? undefined : _setBgColor() }}
    >
      {url !== "N/A" ? (
        <NcImage
          alt={name}
          src={url}
          className="absolute inset-0 w-full h-full object-cover"
        />
      ):
       <span className="wil-avatar__name">{name?.[0] || "DZ"}</span>}
    </div>
  );
};

export default Avatar;
