import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { BlogConverter } from "app/modals/BlogConverter";
import { CategoryConverter } from "app/modals/CategoryConverter";
import { RootState } from "app/store";
import axios from "axios";
import { SinglePageType } from "containers/PageSingle/PageSingle";
import {AuthorDetailConvertor} from "../modals/AuthorDetailConvertor";
import {BlogResponseType, AuthorResponseType, TagsPayload, CategoryPayload, Pageable ,TrendingTopicCategoriesResponseType, Recent, AuthorTypeResponse,FollowUserPayload,NotificationsResponse, CommentsQuery, CommentPayload, CommentUpdatePayload, PreviewBlog} from "types/Blog";
import {PagableResponse} from "../../types";
import { PostAuthorType, PostDataType, TaxonomyType } from "data/types";
import { AuthorConverter } from "app/modals/AuthorConverter";
import { PostDataConverter } from "app/modals/PostDataConverter";
import { Taxonomy } from "app/modals/Taxonomy";
import { AccessPlatform, Status } from "enum";
import { ContactUsPayload } from "types/Auth";

export interface BlogState {
  trendingBlogs: string[];
  latestBlogs: string[];
  currentUserBlogs: {
    content:BlogResponseType[],
    totalElements:number
  }
  allBlog: string[];
  content: any;
  category : CategoryPayload;
  tags: TagsPayload;
  trendingTopic : Taxonomy[]
  recentCatgeories :{id:string | undefined, name : string}[]
  previewBlogData: PreviewBlog
}

const initialState: BlogState = {
  trendingBlogs: [],
  latestBlogs:[],
  currentUserBlogs:{
    content: [],
    totalElements: 0
  },
  content:[],
  allBlog:[],
  category: {
    content: [],
  },
  tags:{
    content: [],
  },
  trendingTopic : [],
  recentCatgeories:[],
  previewBlogData:{
    content:{
      accessPlatform: AccessPlatform.INSIGHTS, 
      alias: false,
      aliasUrl: '',
      altTag: '',
      blogMetaTag: '',
      blogData: '',
      category: '',
      faq: '',
      canonicalUrl: '',
      metaKeyword: '',
      schemaMarkUp: '',
      title: '',
      tags: [],
    },
    image: "",
    tagList:[],
    categoryList:[]
  }
}
//Get All Blogs
export const getAllBlog = createAsyncThunk(
  'user/allblogs',
  async(page: Pageable)=>{
    const response = await axios.post(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/blog/getAll/pageable?pageNo=${page.pageNo}&pageSize=${page.pageSize}`,
    )
    return response.data
  }
)
//Get All LatestBlogs
export const getLatestBlogs = createAsyncThunk(
  'user/getlatestblogs',
  async(page: Pageable)=>{
    const response = await axios.post(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/blog/getAll/pageable?key=LATEST?pageNo=${page.pageNo}&pageSize=${page.pageSize}`)
    return response.data
  }
)
//Get All LatestBlogs
export const getTrendingBlogs = createAsyncThunk(
  'user/gettrendingblogs',
  async(page: Pageable)=>{
    const response = await axios.post(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/blog/getAll/pageable?key=TRENDING?pageNo=${page.pageNo}&pageSize=${page.pageSize}`)
    return response.data
  }
)
//File Upload
export const uploadFile =
  async (file: File) => {
    const url = `${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/blog/upload-file/CKEDITOR`;
    const formData = new FormData();
    formData.append('uploadFile', file)
    const config = { 
      headers: {
        'Content-Type':"multipart/form-data",
      
      }
    };
    
    const response = await axios.post(url, formData, config);
    return response.data
  }
//Create Blog api
export const createBlog = async ( payload: FormData) => {
    const response = await axios.post(
      `${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/blog/create`,
      payload
    );
    return response.data
  }

//Get blogdetail by ID
export const blogDetails = createAsyncThunk(
  'user/blogdetailsbyid',
  async (id: string) => {
    const response = await axios.get(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/blog/get/${id}`)
    return response.data
  }
)
//Edit Blogs user
export const updateBlog = async(payload: FormData) => {
    const response = await axios.put(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/blog/update`,payload)
    return response.data
}

//Get All Category Api
export const getAllCategory = createAsyncThunk(
  'user/getallcategory',
  async () => {
    const params = new URLSearchParams()
    params.set('pageNo', '0');
    params.set('pageSize', '1000');
    params.set('status', Status.ACTIVE);
    const response = await axios.get(`${import.meta.env.VITE_BASE_API_URL}/public/categories/search?${params}`);
    return response.data
  }
)
//Get all tags API
export const getAllTags = createAsyncThunk(
  'user/getalltags',
  async () => {
    const params = new URLSearchParams()
    params.set('pageNo', '0');
    params.set('pageSize', '1000');
    params.set('status', Status.ACTIVE);
    const response = await axios.get(`${import.meta.env.VITE_BASE_API_URL}/public/tags/search?${params}`)
    return response.data
  }
)
//Get current user blogs
export const getBlogsByCurrentUser = createAsyncThunk(
  'user/getblogsbycurrentuser',
    async(params: Pageable)=>{
    const response = await axios.get(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/blog/get/username/pageable?pageNo=${params.pageNo}&pageSize=${params.pageSize}`);
    return response.data
  }
  
)

//Get blog By UniqueURL
export const getBlog = createAsyncThunk(
  'user/getbyuniqueurl',
  async (slug: string): Promise<SinglePageType> => {
    const response = await axios.get<BlogResponseType>(`${import.meta.env.VITE_BASE_API_URL}/public/unique-url/${slug}`)
    if(response.status < 400) {
      return new BlogConverter(response.data);
    }
    return Promise.reject(response);
    
  }
)
// Get Author Detail By UserId
export const getAuthor = async (id: string|number): Promise<PostAuthorType> => {
    const response = await axios.get<AuthorResponseType>(`${import.meta.env.VITE_BASE_API_URL}/public/user/${id}`)
    if(response.status < 400) {
        return new AuthorDetailConvertor(response.data);
    }
    return Promise.reject(response);
}
// Get Top 10 Trending Topic Categories
export const getTop10Categories = createAsyncThunk(
  'user/gettop10categories',
  async (): Promise<{top: TaxonomyType[] , recent:Recent[]}> => {
    const response = await axios.get<TrendingTopicCategoriesResponseType>(`${import.meta.env.VITE_BASE_API_URL}/public/categories/trending?recentCount=1&topCount=10`)
    if(response.status < 400) {
     const top = response.data.top.map((t)=>{
      return new CategoryConverter(t)
     })
    const recent = response.data.recent.map((r) =>{
        return {
          id:r.id,
          name:r.name
        }
    })
    return {top,recent}
    }
    
    return Promise.reject(response);
    
  }
)
// get All Blog Data
export const searchBlogs = async (params: string):Promise<PagableResponse<BlogResponseType>> => {
    const response = await axios.get<PagableResponse<BlogResponseType>>(`${import.meta.env.VITE_BASE_API_URL}/public/blog/search?${params}`)
    if(response.status < 400) {
        return response.data
    }
    return Promise.reject(response);
}
//delete blog
export const deleteBlog = async (id: string) => {
  const response =  await axios.delete(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/blog/delete/${id}`);
  return response.data
};
//Get Editor pick Blogs
export const getEditorBlogs =
  async (page:Pageable):Promise<Array<PostDataType>> =>{
  const response = await axios.get<PagableResponse<BlogResponseType>>(`${import.meta.env.VITE_BASE_API_URL}/public/blog/search?filterAnd=editorPick%7Ceq%7Ctrue%26status%7Ceq%7CAPPROVED%26accessPlatform%7Ceq%7CINSIGHTS&pageNo=${page.pageNo}&pageSize=${page.pageSize}`);
  if(response.status < 400) {
    const post = response.data.content.map((val:BlogResponseType)=>{
      return new PostDataConverter(val)
    })
       return post
      }
      return Promise.reject(response);
  }
  
//Get Top 10 Authors
export const getTop10Authors = async (page: Pageable):Promise<PagableResponse<AuthorTypeResponse>> => {
    const response = await axios.get<PagableResponse<AuthorTypeResponse>>(`${import.meta.env.VITE_BASE_API_URL}/public/discover-authors?pageNo=${page.pageNo}&pageSize=${page.pageSize}`)
    if(response.status < 400) {
      return response.data;
    }

    return Promise.reject(response);
}
//Get Newest Author
export const getNewestAuthor = async ():Promise<Array<PostAuthorType>> => {
  const response = await axios.get<AuthorTypeResponse[]>(`${import.meta.env.VITE_BASE_API_URL}/public/newest-authors`)
  if(response.status < 400) {
   const authors = response.data.map((c) =>{
    return new AuthorConverter(c)
   })
   return authors
  }

  return Promise.reject(response);
}

// Get user Follow Status
export const followUserStatus =
  async (id:string):Promise<{following:boolean}>  => {
    const response = await axios.get(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/user/follow/${id}`)
     if(response.status < 400) {
      return response.data
     }
     return Promise.reject( response.data.error);
  }


// Follow user
export const followUser =
  async (payload:FollowUserPayload):Promise<string> => {
    const response = await axios.post(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/user/follow`,payload)
    if(response.status < 400) {
      return response.data
    }
    return Promise.reject( response.data.error);
}
// Get Blog comments
export const getBlogComments = async(url: CommentsQuery) => {
  const response = await axios.post(`${import.meta.env.VITE_BASE_API_URL}/public/blogComment/get/${url.id}?${url.queryParams}`,{})
  return response.data
}

//get User Notifications 
export const getNotifications = async (page:Pageable)=>{
 const response = await axios.get<PagableResponse<NotificationsResponse>>(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/notification/ALL?pageNo=${page.pageNo}&pageSize=${page.pageSize}`)
  return response.data
}

//get Notifications Read Status
export const updateNotificationStatus = async (id :string)=>{
  const payload = {
    id:[id],
    status:true
  }
  const response = await axios.post<PagableResponse<NotificationsResponse>>(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/notification/read`,payload)
  return response.data
}
// Create Blog Comment
export const createBlogComment = async(payload:CommentPayload) => {
  const response = await axios.post(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/blog/comment/create`,payload)
  return response.data
}
// Update Blog Comment
export const updateBlogComment = async(payload : CommentUpdatePayload) => {
  const response = await axios.patch(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/blog/comment/update`,payload)
  return response.data
}
// Delete Blog Comment
export const deleteBlogComment = async(commentId : number) => {
  const response = await axios.delete(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/blog/comment/delete/${commentId}`)
  return response.data
}
//Get Current User Detail
export const getCurrentUserDetail = async () => {
  const response = await  axios.get(`${import.meta.env.VITE_BASE_API_URL}/insights/api/v1/user/me`)
  return response.data
}


// POST User Query 
export const contactUs = async (payload : ContactUsPayload) => {
  const response = await  axios.post(`${import.meta.env.VITE_BASE_API_URL}/public/contact-us`,payload)
  return response.data
}
export const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers:{
    addBlog: (state, action : PayloadAction<PreviewBlog>) => {
      return {
      ...state,
     previewBlogData: action.payload
    }},
    resetState: (state) => {
      return initialState
    }
  },
  extraReducers : (builder) =>{
    builder.addCase(getAllBlog.fulfilled, (state,action)=> {
      state.allBlog = action.payload
    })
    builder.addCase(getAllCategory.fulfilled,(state,action)=>{
      state.category = action.payload
    })
    builder.addCase(getAllTags.fulfilled,(state,action)=>{
      state.tags = action.payload
    })
    builder.addCase(getBlogsByCurrentUser.fulfilled,(state,action)=>{
      state.currentUserBlogs = action.payload
    })
    builder.addCase(getLatestBlogs.fulfilled,(state,action)=>{
      state.latestBlogs = action.payload
    })
    builder.addCase(getTrendingBlogs.fulfilled,(state,action)=>{
      state.trendingBlogs = action.payload
    })
    builder.addCase(getTop10Categories.fulfilled,(state,action)=>{
      state.trendingTopic = action.payload.top
      state.recentCatgeories = action.payload.recent
    })
  },
});
export const { addBlog, resetState } = blogSlice.actions;
export const selectBlogState = (state: RootState) => state.blogs
export default blogSlice.reducer;