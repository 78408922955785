import { TaxonomyType } from "data/types";

export class Taxonomy implements TaxonomyType {
    id: string | number;
    name: string;
    href: string;
    taxonomy: "category" | "tag";

    constructor(id: string, name: string, slug: string, type: "category" | "tag") {
        this.id = id;
        //@TODO to be updated after category response update
        this.name = name || 'Industry';
        this.href = `/discover?category=${id}`;
        this.taxonomy = type
    }
}