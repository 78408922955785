import axios, { AxiosError, AxiosRequestConfig } from "axios";

const setUpInterceptor = (store: any) => {
  const handleError = async (error: AxiosError) => {
    if (error?.response?.status === 401) {
      store.dispatch({ type: "user/LOGOUT/fulfilled"})
        .unwrap()
        .finally(() => {
            window.location.href = "/";
        });
    }
    return Promise.reject(error?.response?.data || error?.response || error)
  }

  axios.interceptors.request.use(
    async (config: any | AxiosRequestConfig) => {
      const authConfig = store?.getState()?.auth?.token
        ? { "Authorization": `Bearer ${store?.getState()?.auth?.token}` }
        : {}
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        if(config.url !== "https://oauth2.googleapis.com/token" ) {
          config.headers = {
            "Accept": "application/json",
            "Content-type": "application/json",
            ...authConfig,
            "user_timezone" :timeZone,
            ...config.headers
          }
        } 
      return config
    }
  )

  axios.interceptors.response.use((response) => response, handleError);
}

export default setUpInterceptor