import { useState, useEffect } from 'react';
import Input from "components/Input/Input";
//MRT Imports
import MaterialReactTable, { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';

//Material-UI Imports
import {
  createTheme,
  ThemeProvider,
  ListItemIcon,
  MenuItem,
  Card
} from '@mui/material';

//Icons Import
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Status } from 'enum';
import { useAppSelector } from 'app/hooks';
import { selectDarkmodeState } from 'app/darkmode/darkmode';

export type DataTypes = { 
  id?:string;
  totalviewer?: string;
  status?: string;
  title?: string,
};

export type PropTypes = {
  data: DataTypes[];
  column: MRT_ColumnDef<DataTypes>[];
  edit?: Function;
  handleDelete?: Function;
  setTableRefresh?: boolean;
  onHandlePagination?: Function;
  rowCount?: number;
};

const ReactTable = (props:PropTypes) => {
  const {
    column,
    data,
    onHandlePagination = () => { },
    rowCount,
    setTableRefresh,
    edit = () => {},
    handleDelete = () => {},
  } = props;
  const darkmodeState = useAppSelector(selectDarkmodeState);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const theme = createTheme({
    palette: {
      mode: darkmodeState ? "dark" : "light",
      ...(darkmodeState && {
        background: {
          default: '#131727'
        },
      }),
    },
  });

  useEffect(() => {
    (setTableRefresh || onHandlePagination) && onHandlePagination(pagination?.pageIndex, pagination?.pageSize);
  }, [JSON.stringify(pagination),setTableRefresh]);

  return (  
    <Card variant="outlined" sx={{ borderRadius: '1%' }}>
     <ThemeProvider theme={theme}>
      <MaterialReactTable
       columns={column}
       data={data?.length ? data : []}
       enableDensityToggle={false}
       enableColumnFilters={false}
       enableColumnResizing
       enableColumnOrdering={false}
       enableRowActions
       manualPagination
       enableRowSelection={false}
       enableHiding={false}
       enableClickToCopy
       positionToolbarAlertBanner="bottom"
       onPaginationChange={setPagination}
       state={{ pagination }}
       rowCount={rowCount}
        renderRowActionMenuItems={({ closeMenu,row }) => [
          <MenuItem
            onClick={() => {
              closeMenu();
              handleDelete(row?.original?.id)
            }}
            sx={{ m: 0 }}
            key={0}
            disabled={row?.original?.status === Status.PENDING ? false : true }
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            Delete
          </MenuItem>,
          <MenuItem
            onClick={() => {
              closeMenu();
              edit(row?.original?.id)
            }}
            sx={{ m: 0 }}
            key={1}
            disabled={row?.original?.status === Status.PENDING ? false : true}
          >
            <ListItemIcon >
              <EditIcon />
            </ListItemIcon>
            Edit
          </MenuItem>
        ]}

      />
      </ThemeProvider>
    </Card>
  );
};

export default ReactTable;
