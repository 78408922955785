import React, { FC, useEffect, useRef,useState } from "react";
import Tag from "components/Tag/Tag";
import { SinglePageType } from "./PageSingle";
import SingleAuthor from "./SingleAuthor";
import SingleCommentForm from "./SingleCommentForm";
import SingleCommentLists from "./SingleCommentLists";
import { useLocation } from "react-router";
import { createBlogComment, getBlogComments } from "app/blogs/blogs";
import { CommentTypeResponse, Pageable } from "types/Blog";
import { CommentConverter } from "app/modals/CommentConverter";
import { nest } from "data/single";
import { CommentType } from "components/CommentCard/CommentCard";
import { useAppSelector } from "app/hooks";
import { selectAuthState } from "app/auth/authSlice";
import { Alert } from "components";
export interface SingleContentProps {
  data: SinglePageType;
}

const SingleContent: FC<SingleContentProps> = ({ data }) => {
  const {token} = useAppSelector(selectAuthState);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [page, setPage] = useState<Pageable>({
    pageNo: 0,
    pageSize: 10,
  });
  const [error, setError] =  useState<string>('')
  const { tags, author, commentCount , id } = data;
  const commentRef = useRef<HTMLDivElement>(null);
  const [comments , setComments] = useState<CommentType[]>([])
  const [isCommentCreated , setIsCommentCreated] = useState<Boolean>(false)
  const location = useLocation();
useEffect(() =>{
  getComments()
},[isCommentCreated,page])


  useEffect(() => {
    //  SCROLL TO COMMENT AREA
    if (location.hash !== "#comment") {
      return;
    }
    //
    if (location.hash === "#comment") {
      setTimeout(() => {
        if (commentRef.current) {
          commentRef.current.scrollIntoView();
        }
      }, 500);
    }
  }, [location]);
// Handle Comment Submit
function handleSubmit(content:string){
const payload = {
  blogId : id,
  content:content,
}

createBlogComment(payload)
.then((res) =>{
  setIsCommentCreated(!isCommentCreated)
  if (textareaRef.current) {
    textareaRef.current.value = '';
  }
  
})
.catch((err) =>{
  setError(err?.message || "Something wrong, Please try again.")
})
}
// Handle Reply Submit
function getAllComments(){
     setIsCommentCreated(!isCommentCreated);
}
// Hanlde View More
 function handleViewMore(){
  setPage((prev) =>{
    return {
      ...prev,
      pageSize:prev.pageSize + 10 
    }
  })
}
//Get Blog Comments
const getComments = () =>{
  const params = {
    pageNo: `${page.pageNo}`,
    pageSize: `${page.pageSize}`,
   
  }
  const queryParams= new URLSearchParams(params).toString();
  getBlogComments({queryParams,id})
  .then((res) =>{
    const comments = res.content.map((comment : CommentTypeResponse) =>{
        return  new CommentConverter(comment)
    })
   setComments(nest(comments,null))
  })
  .catch((err) =>{
    setError(err?.message || "Something wrong, Please try again.")
  })
}
  return (
    <div className="nc-SingleContent space-y-10">
       {error && <Alert children={error} type="error" onClose={() => setError("")} />}
      {/* ENTRY CONTENT */}
      <div
        id="single-entry-content"
        className="editor prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert leading-6"
        dangerouslySetInnerHTML={{__html: data.content as string}}
      >
        
        {/* THIS IS THE DEMP CONTENT */}
        {/* IF YOUR DATA IS JSON, YOU CAN USE render with html-react-parser (https://www.npmjs.com/package/html-react-parser) */}
        
      </div>

      {/* TAGS */}
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        {tags.map((item) => (
          <Tag hideCount key={item.id} tag={item} className="mr-2 mb-2" />
        ))}
      </div>

      {/* AUTHOR */}
      <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
       <div className="max-w-screen-md mx-auto ">
        <SingleAuthor author={author} />
      </div>
      {/* COMMENT FORM */}
      <div
        id="comment"
        ref={commentRef}
        className="max-w-screen-md mx-auto pt-5"
      >
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Responses ({commentCount})
        </h3>
      { token &&  <SingleCommentForm
          onClickSubmit={handleSubmit}
          onClickCancel={(id) => console.log(id)}
          textareaRef={textareaRef}
        />}
      </div>

      {/* COMMENTS LIST */}
      <div className="max-w-screen-md mx-auto">
          <SingleCommentLists 
            handleViewMore={handleViewMore}
            getAllComments={getAllComments}
            blogId={id}
            comments={comments} 
          />
      </div>
    </div>
  );
};

export default SingleContent;
