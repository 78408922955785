import React from "react";
import { useStore } from "react-redux";
import MyRouter from "routers/index";
import setUpInterceptor from "app/axios.interceptor";

function App() {
  const store = useStore();
  setUpInterceptor(store);
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
