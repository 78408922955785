import React, { FC, useCallback, useEffect, useState } from "react";
import ModalCategories from "./ModalCategories";
import ModalTags from "./ModalTags";
import { PostAuthorType, PostDataType, TaxonomyType } from "data/types";
import Pagination from "components/Pagination/Pagination";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import NcImage from "components/NcImage/NcImage";
import Card11 from "components/Card11/Card11";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import ButtonSecondary from "components/Button/ButtonSecondary";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import { DEMO_AUTHORS } from "data/authors";
import { useAppDispatch } from "app/hooks";
import { getAllCategory, getAllTags, getTop10Authors, searchBlogs } from "app/blogs/blogs";
import { CategoryConverter } from "app/modals/CategoryConverter";
import { TagsConverter } from "app/modals/TagConverter";
import { PostDataConverter } from "app/modals/PostDataConverter";
import { CategoryResponse, TagsResponse } from "types/Blog";
import { Alert } from "components";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import { AuthorConverter } from "app/modals/AuthorConverter";
import AuthorPagination from "components/Pagination/AuthorPagination";
import Loader from "components/Loader";

export interface PageArchiveProps {
  className?: string;
}

const PageArchive: FC<PageArchiveProps> = ({ className = "" }) => {
  const [category, setCategory] = useState<TaxonomyType[]>([])
  const [tags, setTags] = useState<TaxonomyType[]>([])
  const [posts, setPosts] = useState<PostDataType[]>([])
  const [authors, setAuthors] = useState<PostAuthorType[]>([])
  const [eliteAuthors, setEliteAuthors] = useState<PostAuthorType[]>([])
  const [error, setError] =  useState<string>('')
  const currentUrl = window.location.href
  const queryParams = new URL(currentUrl); 
  const searchParams = new URLSearchParams(queryParams.search);
  const [totalElements, setTotalElements] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentAuthorPage, setCurrentAuthorPage] = useState<number>(1);
  const [authorTotalElements, setAuthorTotalElements] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const categoryId = searchParams.get("category");
  const tagId = searchParams.get("tag");
  const sort = searchParams.get("sort")
  const [pagination, setPagination] = useState({
    pageNo: 0, pageSize:8
  })
  const [authorpagination, setAuthorPagination] = useState({
    pageNo: 0, pageSize:8
  })
  const dispatch = useAppDispatch()
  const FILTERS = [
    { name: "Most Recent" },
    // { name: "Curated by Admin" },
    // { name: "Most Appreciated" },
    // { name: "Most Discussed" },
    { name: "Most Viewed" },
  ];

  const totalPages = Math.ceil(totalElements/pagination.pageSize) 
  const authorTotalPages = Math.ceil(authorTotalElements/authorpagination.pageSize) 
  const handlePageChange = (page:{ pageNo: number, pageSize: number }) => {
    setPagination({pageNo: page.pageNo, pageSize: page.pageSize})
    setCurrentPage(page.pageNo + 1);
    const pageNumber = page.pageNo
    const params = {
      pageNo: pageNumber.toString(),
      pageSize: page.pageSize.toString(),
      filterAnd: categoryId === null ? `status|eq|APPROVED&accessPlatform|eq|INSIGHTS&category|"isnotnull"` : `status|eq|APPROVED&accessPlatform|eq|INSIGHTS&category|eq|${categoryId}`,
      filterOr: tagId === null ? `tags|"isnotnull"` : `tags|eq|${tagId}`,
      orders: sort === null ? `publishedDate|desc` : `${sort}|desc`,            
    }
    const urlPath = new URLSearchParams(params).toString();
    searchBlogs(urlPath)
    .then((res)=>{
      if(res) {     
        setTotalElements(res.totalElements)
        const data = res?.content?.map((t) => {
          return new PostDataConverter(t)
        })
        return setPosts(data)        
      }
    })
    .catch((e)=> setError(e.message || "Something wrong, Please try again."))
  }
  const handleAuthorPageChange = (page:{ pageNo: number, pageSize: number }) => {
    setAuthorPagination({pageNo: page.pageNo, pageSize: page.pageSize})
    setCurrentAuthorPage(page.pageNo)
    const pageNumber = page.pageNo
    const params = {
      pageNo: pageNumber,
      pageSize: page.pageSize,
    }
    getTop10Authors(params)
    .then((res) => {
        const _post = res.content.map((val)=>{
          return new AuthorConverter(val)
        })
        setAuthors(_post);
      setAuthorTotalElements(res.totalElements)
    })
    .catch((err) => setError(err?.message || "Something wrong, Please try again."));   
  }

  const fetchBlogs = () => {
    const query = searchParams.get("page")
    const params = {
      pageNo: pagination.pageNo.toString(),
      pageSize: pagination.pageSize.toString(),
      filterAnd: categoryId === null ? `status|eq|APPROVED&accessPlatform|eq|INSIGHTS&category|"isnotnull"` : `status|eq|APPROVED&accessPlatform|eq|INSIGHTS&category|eq|${categoryId}`,
      filterOr: tagId === null ? `tags|"isnotnull"` : `tags|eq|${tagId}`,
      orders: query === 'popularPost' ? `totalViewer|desc` : sort === null ? `publishedDate|desc` : `${sort}|desc`,            
    }
    const urlPath= new URLSearchParams(params).toString();

    searchBlogs(urlPath)
    .then((res)=>{
      if(res) {     
        setTotalElements(res.totalElements)
        const data = res?.content?.map((t) => {
          return new PostDataConverter(t)
        })
        return setPosts(data)        
      }
    })
    .catch((e)=> setError(e.message || "Something wrong, Please try again."))
  }

  useEffect(() => {
    fetchBlogs();
  }, [pagination, categoryId, tagId, sort])

  useEffect(() => {
    setLoading(true)

    dispatch(getAllCategory())
    .unwrap()   
    .then((res) => {
      setLoading(false)
      if(res) {        
        const data = res?.content?.map((t: CategoryResponse) => {
          return new CategoryConverter(t)
        })
        return setCategory(data)
      }
    })
    .catch((e)=> {
      setLoading(false)
      setError(e.message || "Something wrong, Please try again.")
    })
    const param = {
      pageNo: 0,
      pageSize: 10,
    }
    getTop10Authors(param)
    .then((res) => {
        const _post = res.content.map((val)=>{
          return new AuthorConverter(val)
        })
        setEliteAuthors(_post);
      setAuthorTotalElements(res.totalElements)
    })
    .catch((err) => setError(err?.message || "Something wrong, Please try again."));
    dispatch(getAllTags())
    .unwrap()
    .then((res) => {
      if(res) {        
        const data = res?.content?.map((t: TagsResponse) => {
          return new TagsConverter(t)
        })
        return setTags(data)
      }
    })
    .catch((e)=> setError(e.message || "Something wrong, Please try again."))

    getTop10Authors(authorpagination)
      .then((res) => {
        setAuthorTotalElements(res.totalElements)
        const _post = res.content.map((val)=>{
          return new AuthorConverter(val)
        })
        setAuthors(_post);
      })
      .catch((err) => setError(err?.message || "Something wrong, Please try again."));
  },[categoryId, tagId, sort,authorpagination])
  return (
    <div
      className={`nc-PageArchive overflow-hidden ${className}`}
      data-nc-id="PageArchive"
    >
    {loading && (
      <div className="w-full top-0 bottom-0 right-0 left-0 fixed flex bg-[#111827e6] items-center z-10 justify-center	">
        <Loader />
      </div>
    )}
      <Helmet>
        <title>DZ Insights- Discover The World</title>
        <link rel = "canonical" href = {window.location.href} />
        <meta name="title" content=" Discover Insightful Blogs on DZ Insights" />
        <meta name="description" content="Read the latest blog, articles, news, and stories on DZ Insights." /> 
      </Helmet>
      {error && <Alert type="error" children={error} onClose={() => setError("")} />}
      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
        { category.map((i) => {
          return i.id === categoryId ? <div className="rounded-3xl md:rounded-[40px] relative aspect-w-16 aspect-h-13 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-5 overflow-hidden ">
          <NcImage
            containerClassName="absolute inset-0"
            src={i.thumbnail}
            className="object-cover w-full h-full"
          />
          <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
            <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
              {i.name}
            </h2>
            <span className="block mt-4 text-neutral-300">
              {`${i.count} Articles`}      
            </span>
          </div>
        </div> : ''
        })}        
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <div>
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <div className="flex space-x-2.5">
              <ModalCategories categories={category} />
              <ModalTags tags={tags} />
            </div>
            <div className="block my-4 border-b w-full border-neutral-100 sm:hidden"></div>
            <div className="flex justify-end">
              <ArchiveFilterListBox lists={FILTERS}/>
            </div>
          </div>

          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
            {posts.map((post) => (
              <Card11 key={post.id} post={post} />
            ))}
          </div>
          {/* PAGINATIONS */}
            <Pagination 
              onPageChange={handlePageChange}
              totalPage={totalPages}
              pageSize={pagination.pageSize}
            />
        </div>

        {/* MORE SECTIONS */}
        {authors.length>0 && <>
       <div className="relative py-6">
        <BackgroundSection />
         <SectionGridAuthorBox
            className="py-16 lg:py-28"
            heading="Discover Authors"
            subHeading=""
            authors={authors}
          />
          </div>
          <AuthorPagination 
              onPageChange={handleAuthorPageChange}
              totalPage={authorTotalPages}
              pageSize={authorpagination.pageSize}
            /> </>}


        {/* === SECTION 5 === */}
    {category.length > 0 && <div className="relative py-16">
      {/* <BackgroundSection /> */}
      <SectionGridCategoryBox
        categories={category.filter((_, i) => i < 10)}
      />
    </div>}

        {/* === SECTION 5 === */}
        {eliteAuthors.length > 0 ? <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewAuthors
          heading="Top Elite Authors"
          subHeading="Discover our elite writers"
          authors={eliteAuthors.filter((_, i) => i < 10)}
          uniqueSliderClass="PageArchive"
        /> </div>: null}

        {/* SUBCRIBES */}
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageArchive;
