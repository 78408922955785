import React, { FC } from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";
import Textarea from "components/Textarea/Textarea";

export interface SingleCommentFormProps { 
  className?: string;
  commentId?: number | string;
  onClickSubmit: (content: string ,parentId?:string|number) => void;
  onClickCancel: (id?: number) => void;
  textareaRef?: React.MutableRefObject<null | HTMLTextAreaElement>;
  defaultValue?: string;
  rows?: number;
}

const SingleCommentForm: FC<SingleCommentFormProps> = ({
  className = "mt-5",
  commentId,
  onClickSubmit,
  onClickCancel,
  textareaRef,
  defaultValue = "",
  rows = 4,
}) => {
 

  
//Handle Cancel
  const handleCancel = () =>{
    onClickCancel()
    if (textareaRef?.current) {
      textareaRef.current.value = '';
    }
  }
  return (
    <form  className={`nc-SingleCommentForm ${className}`}>
      <Textarea
        placeholder="Add to discussion"
        ref={textareaRef}
        required={true}
        defaultValue={defaultValue}
        rows={rows}
      />
      <div className="mt-2 space-x-3">
        <ButtonPrimary    onClick={() => {onClickSubmit(textareaRef?.current?.value || "")}} type="button">
          Submit
        </ButtonPrimary>
        <ButtonSecondary type="button" onClick={handleCancel}>
          Cancel
        </ButtonSecondary>
      </div>
    </form>
  );
};

export default SingleCommentForm;
