import React, {FC,useEffect,useState} from 'react'
import { Pageable } from 'types/Blog'
import { useAppDispatch,useAppSelector } from 'app/hooks'
import { selectBlogState,getAllBlog } from 'app/blogs/blogs'

const BlogsContainer: React.FC = () => {
    const dispatch = useAppDispatch()
    const { allBlog }: any = useAppSelector(selectBlogState);
    const [page, setPage] = useState<Pageable>({
      pageNo: 0, pageSize: 4
    })
    useEffect(() => {
        dispatch(getAllBlog(page))
    }, [])
  return (
      <div> 
        {
          allBlog?.content?.map((blogs: any)=>{
            return(
            <h1 key={blogs.id}>Blog Page</h1>
            )
          })
        }
      </div>
  )
}

export default BlogsContainer