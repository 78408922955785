import {PostAuthorType, PostDataType, TaxonomyType} from "data/types";
import {BlogResponseType} from "../../types/Blog";
import {Author} from "./Author";
import {Taxonomy} from "./Taxonomy";

export class PostDataConverter implements PostDataType {
    id: string | number;
    title: string;
    featuredImage: string;
    date: string;
    desc?: string;
    href: string;
    commentCount: number;
    viewdCount: number;
    videoUrl?: string;
    audioUrl?: string;
    galleryImgs?: string[];
    altTag?: string;
    readingTime: number | string;
    like: {
        count: number;
        isLiked: boolean;
    };
    bookmark: {
        count: number;
        isBookmarked: boolean;
    };
    categories: TaxonomyType[];
    author: PostAuthorType;
    postType: "standard" | "video" | "gallery" | "audio";


    constructor (data: BlogResponseType) {
        this.id = data.id;
        this.title = data.title;
        this.featuredImage = data?.bannerWebpImageUrl || "";
        this.date = data.publishedDate;
        this.desc = data.blogMetaTag;
        this.href = `/blog/${data.uniqueUrl}`;
        this.commentCount = data.comments;
        this.viewdCount = data.totalViewer;
        this.videoUrl =  "";
        this.audioUrl =  "";
        this.galleryImgs =  [data?.bannerWebpImageUrl || ""];
        this.altTag = data.title;
        this.like =  {
            count: data.likes,
            isLiked: false
        };
        this.bookmark = {
            count: 0,
            isBookmarked: false
        };
        this.readingTime = data.readTime;
        this.categories = [new Taxonomy(data?.category?.id || "", data?.category?.name || "", data?.category?.slug || "", "category")];
        this.author = new Author(data.createdBy)
        this.postType = "standard";
    }
}