import { FC } from "react";
import { Helmet } from "react-helmet";
import LayoutPage from "components/LayoutPage/LayoutPage";

const TermsAndConditions: FC = () => {

  return (
    <div className="">
        <Helmet>
        <title>Terms & Conditions- DZ Insights</title>
        <meta name="title" content="DZ Insights Terms & Conditions" />
        <meta name="description" content="Navigate DZ Insights Terms and Conditions to know your obligations and rights in a simplified way!" />
      </Helmet>
      <LayoutPage
        subHeading=""
        headingEmoji="🧾"
        heading="Terms & Conditions"
      >
       <div>
     <div className="">
      <h2 className="font-semibold flex items-center justify-center">Terms and Conditions</h2>
      <br/>
      <p >
        Welcome to the Terms and Conditions ("Agreement") of DZ Insights ("Company"). This Agreement sets forth the legally binding terms and conditions for your use of our website and services. By accessing or using our website or services, you agree to be bound by this Agreement. Please read these terms carefully before using our website or services.
      </p>
      <br/>
      <h2 className="font-semibold font-style: italic">1. Acceptance of Terms</h2>
      <br/>
      <p>
        By accessing or using the Company's website or services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions, as well as any additional terms and conditions, policies, or disclaimers provided by the Company from time to time.
      </p>
      <br/>
      <h2 className="font-semibold font-style: italic">2. Use of the Website</h2>
      <br/>
      <h3 className="font-style: italic">2.1 Account Creation</h3>
      <br/>
      <p>
        In order to access certain features of our website or services, you may be required to create an account. You agree to provide accurate, current, and complete required information during the registration process and to update such information as necessary to keep it accurate, current, and complete.
      </p>
      <br/>
      <h3 className="font-style: italic">2.2 User Responsibilities</h3>
      <br/>
      <p>
        You are responsible for maintaining the confidentiality of your account login information and are fully responsible for all activities that occur under your account. You agree to notify the Company immediately of any unauthorized use or suspected unauthorized use of your account or any other breach of security.
      </p>
      <br/>
      <h3 className="font-style: italic">2.3 Prohibited Activities</h3>
      <p>
        When using our website or services, you agree not to engage in any of the following activities:
      </p>
        <li>Impersonating any person or entity or falsely stating or otherwise misrepresenting your affiliation with a person or entity.</li>
        <li>Interfering with or disrupting the operation of our website or services.</li>
        <li>Engaging in any form of automated data collection or extraction.</li>
        <li>Uploading or transmitting viruses or any other type of malicious code.</li>
        <li>Harassing, abusing, or harming another person or entity.</li>
        <br/>
      <h2  className="font-semibold font-style: italic">3. Intellectual Property Rights</h2>
      <br/>
      <h3  className="font-style: italic">3.1 Ownership</h3>
      <br/>
      <p>
        The Company and its licensors own all rights, titles, and interests in and to the website, services, and all related intellectual property rights. The website and services are protected by copyright, trademark, and other laws of India, the United States, and other countries.
      </p>
      <br/>
      <h3  className="font-style: italic">3.2 Limited License</h3>
      <br/>
      <p>
        The Company grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the website and services for your personal or internal business use, subject to the terms and conditions of this Agreement.
      </p>
      <br/>
      <h3  className="font-style: italic">3.3 Restrictions</h3>
      <br/>
      <p>
        You shall not reproduce, modify, distribute, sell, lease, or create derivative works based on the website or services, in whole or in part, unless expressly authorized by the Company in writing.
      </p>
      <br/>
      <h2  className="font-semibold font-style: italic">4. Limitation of Liability</h2>
      <br/>
      <p>
        To the maximum extent permitted by law, in no event shall the Company or its directors, officers, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, arising out of or in connection with your use of the website or services.
      </p>
      <br/>
      <h2  className="font-semibold font-style: italic">5. Indemnification</h2>
      <br/>
      <p>
        To the fullest extent permitted by applicable law, you will indemnify, defend and hold harmless DZ Insights and its officers, directors, agents, partners, and employees (individually and collectively, the “DZ Insights Parties”) from and against any losses, liabilities, claims, demands, damages, expenses, or costs (“Claims”) arising out of or related to your violation, misappropriation, or infringement of any rights of another (including intellectual property rights or privacy rights) or your violation of the law. You agree to promptly notify DZ Insights Parties of any third-party Claims, cooperate with DZ Insights Parties in defending such Claims, and pay all fees, costs, and expenses associated with defending such Claims (including attorneys’ fees). You also agree that the DZ Insights Parties will have control of the defense or settlement, at DZ Insight's sole option, of any third-party Claims.
      </p>
      <br/>
      <h2  className="font-semibold font-style: italic">6. Governing Law and Jurisdiction</h2>
      <br/>
      <p>
        This Agreement shall be governed by and construed in accordance with the laws of the jurisdiction where the Company is registered. Any disputes arising under or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts in that jurisdiction.
      </p>
      <br/>
      <h2 className="font-semibold font-style: italic">7. Severability</h2>
      <br/>
      <p>
        If any provision of this Agreement is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect.
      </p>
      <br/>
      <h2 className="font-semibold font-style: italic">8. Entire Agreement</h2>
      <br/>
      <p>
        This Agreement constitutes the entire agreement between you and DZ Insights regarding your use of the website and services, superseding any prior agreements or understandings, whether written or oral.
      </p>
      <br/>
      <h2 className="font-semibold font-style: italic">9. Modifications</h2>
      <br/>
      <p>
        The Company reserves the right, at its sole discretion, to modify or replace any part of this Agreement. It is your responsibility to check this Agreement periodically for changes. Your continued use of the website or services following the posting of any changes to this Agreement constitutes acceptance of those changes.
      </p>
      <br/>
      <h2 className="font-semibold font-style: italic">10. Contact Information</h2>
      <br/>
      <p>
        If you have any questions about this Agreement, please contact us at <a aria-label="contact dz insights" href="mailto:info@dzinsights.com">info@dzinsights.com</a>.
      </p>
      
      <p>
        By using our website or services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. These terms govern your relationship with DZ Insights, so please read them carefully. If you do not agree with any part of these terms, please do not use our website or services.
      </p>
      <br/>
      <p>
        Thank you for choosing DZ Insights! We appreciate your business.
      </p>
    </div>
     </div>
   
      </LayoutPage>
    </div>
  );
};

export default TermsAndConditions;
